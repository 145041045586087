import paper from 'paper'

// These are all the maths functions required that don't come with paper, but use paper as a base

export function eq(a, b, epsilon=.00001) {
  if(typeof a === 'number') {
    return Math.abs(a - b) < epsilon
  } else if(Array.isArray(a)) {
    for(let i = 0; i !== a.length; ++i) {
      if(Math.abs(a - b) > epsilon) return false
    }
    return true
  }
}

export function eqPoints(A, B) {
  return eq(A.x, B.x) && eq(A.y, B.y)
}

export function lerp (A, B, u) {
  return (1. - u) * A + u * B
}

export function lerpPoints (A, B, u) {
  const mu = 1. - u
  return {
    x: mu * A.x + u * B.x,
    y: mu * A.y + u * B.y
  }
}

export function smoothStep (A, B, u) {
  if(u < A) return 0.
  if(u >= B) return 1.
  u = (u - A) / (B - A)
  return u * u * (3. - 2. * u)
}

export function getDir(connector) {
  return connector.head.subtract(connector.tail)
}

export function dot(A, B) {
  return A.x * B.x + A.y * B.y
}

export function distSq (A, B) {
  const x = A.x - B.x, y = A.y - B.y
  return x * x + y * y
}

export function perp(A) {
  return new paper.Point(-A.y, A.x)
}

export function dotPerp(A, B) {
  return A.y * B.x - A.x * B.y
}

export function getAngle(dirA, dirB) {
  return Math.acos(dot(dirA, dirB))
}

export function prevRing(arr, idx) {
  return idx === 0 ? arr[arr.length - 1] : arr[idx - 1]
}

export function nextRing(arr, idx) {
  return arr[(idx+1)%arr.length]
}

export function neighbours(arr, idx) {
  return [prevRing(arr, idx), nextRing(arr, idx)]
}

export function neighbourhood(arr, idx) {
  return [prevRing(arr, idx), arr[idx], nextRing(arr, idx)]
}

export function intersects (A, B, dist) {
  return distSq(A, B) < dist
}

export function buildRange (max, min=0) {
  const arr = new Array(max - min)
  for(let i = 0; i !== max; ++i) {
    arr[i] = min + i
  }
  return arr
}

/*
 * A & B are segment arrays of paper.Points, result must equal A & B in length
 */
export function lerpSegments (A, B, t, result) {
  console.assert(A.length === B.length && A.length === result.length, "lerpSegments length issue", A, B)
  for(let i = 0; i !== A.length; ++i) {
    const a = A[i]; const b = B[i]; const r = result[i];
    r.point.x = lerp(a.point.x, b.point.x, t)
    r.point.y = lerp(a.point.y, b.point.y, t)
    r.handleIn.x = lerp(a.handleIn.x, b.handleIn.x, t)
    r.handleIn.y = lerp(a.handleIn.y, b.handleIn.y, t)
    r.handleOut.x = lerp(a.handleOut.x, b.handleOut.x, t)
    r.handleOut.y = lerp(a.handleOut.y, b.handleOut.y, t)
  }
}
