import paper from 'paper'
import { smooth, SplineInput } from '../splineInput'
import {buildRange, lerpSegments} from '../../maths/maths-utils'
import { setupConstraints } from '../../animation/track';

const defaultState = `["Path",{"applyMatrix":true,"segments":[[[227.86953,650.30443],[10.37943,10.10558],[-10.37943,-10.10558]],[[226.20467,585.62283],[-9.42753,16.89899],[6.78443,-13.81841]],[[246.6,567.7],[-2.23334,1.46784],[2.23334,-1.46784]],[[263.42705,561.15877],[-2.91094,0.48714],[2.91094,-0.48713]],[[295.11561,559.58108],[-12.63738,-3.13535],[13.81371,1.98421]],[[344.05974,583.13026],[-4.76225,-3.62743],[4.76225,3.62743]],[[374.48645,620.03829],[-3.30813,-10.25514],[3.30813,10.25514]],[[399.19522,754.04921],[9.42396,-1.10771],[-9.42396,1.10771]]]}]`
const epiHalfState = `["Path",{"applyMatrix":true,"segments":[[[227.86953,650.30443],[10.37943,10.10558],[-10.37943,-10.10558]],[[226.20467,585.62283],[-9.42753,16.89899],[6.78443,-13.81841]],[[246.6,567.7],[-2.23334,1.46784],[2.23334,-1.46784]],[[263.42705,561.15877],[-2.91094,0.48714],[2.91094,-0.48713]],[[295.11561,559.58108],[-12.63738,-3.13535],[13.81371,1.98421]],[[344.05974,583.13026],[-4.76225,-3.62743],[4.76225,3.62743]],[[395.49863,639.75866],[-9.38587,-28.58424],[9.38587,29.86413]],[[411.71059,756.65542],[15.3587,-37.11685],[-6.82609,15.3587]]]}]`
const epiglottisState = `["Path",{"applyMatrix":true,"segments":[[[227.86953,650.30443],[10.37943,10.10558],[-10.37943,-10.10558]],[[225.6997,597.94887],[-9.42753,16.89899],[6.78443,-13.81841]],[[255.56383,573.20431],[-11.94565,2.98641],[15.3587,-2.98641]],[[297.37362,568.938],[-2.91094,0.48714],[33.27718,2.98641]],[[352.62227,582.59684],[-17.49185,-10.23913],[13.81371,1.98421]],[[400.40488,622.70011],[-7.25272,-12.79891],[13.22555,19.19837]],[[434.53532,708.02621],[8.95924,-43.08968],[-7.25272,30.7174]],[[416.61684,758.3686],[8.10598,-9.38587],[-14.50544,8.53261]]]}]`

export default class Tongue {
  constructor (root, path, sim, { colour, width }, hasControls, jaw, epiglottis) {
    this.root = root
    this.path = path
    this.jawInst = jaw
    this.epiInst = epiglottis

    this.hasControls = hasControls

    this.animationDuration = 0.5
    this.tongueRaf = 0. // This is the token for the tongue point animations
    this.jawRaf = 0. // This is the token for the jaw & transform animations, not the tongue animations
    this.epiRaf = 0. // Token for the epiglottis animation

    const A = new paper.Path({ visible: false })
    A.importJSON(defaultState)
    this.defaultPath = A.segments
    const B = new paper.Path({ visible: false })
    B.importJSON(epiHalfState)
    this.epiHalfPath = B.segments
    const C = new paper.Path({ visible: false })
    C.importJSON(epiglottisState)
    this.epiglottisPath = C.segments

    path.strokeColor = colour
    path.name = 'tongue'
    path.visible = false

    this.sim = sim      // This sim is used only during positioning of the curve points.
    this.curve = null   // Stores the representation of the curve
    this.buildTongue(colour ?? 'black', width ?? 1, hasControls ? 4 : 1.)

    this.root.addChild(path)

    this.mode_ = 'Spline' // Can be 'Bands' or 'Spline'

    this.jawState = false
    this.epiglottisState = false;
    this.epiHalfState = false;
    this.labioDentalState = false;

    this.dragging = -1 // Gets the dragging point

    this.root.addChild(this.guides)
  }

  get aniDuration () {
    return this.animationDuration
  }

  set aniDuration (value) {
    this.animationDuration = value
  }

  get animator () {
    return this.animator_
  }

  set animator (animator) {
    this.animator_ = animator
  }

  get jaw () {
    return this.jawState
  }

  set jaw (value) {
    if(this.jawState === value) {
      this.curve.segments[7].point = this.epiInst.epiglottisPoint.subtract(this.jawInst.offset)
      this.spline.updateControls()
      return
    }
    this.jawState = value

    if(this.jawRaf) cancelAnimationFrame(this.jawRaf)

    // While the jaw is translating, move the uvular point.
    const start = performance.now()

    const draw = () => {
      const t = ((performance.now() - start) * 1e-3) / this.animationDuration
      this.curve.segments[7].point = this.epiInst.epiglottisPoint.subtract(this.jawInst.offset)

      if(t < 1.) this.jawRaf = requestAnimationFrame(draw)
      else this.jawRaf = 0.
      this.spline.updateControls()
    }

    draw()
  }

  interpolateSpline (source, target, result, duration) {
    const start = performance.now()
    const scale = 1./duration

    if(this.epiRaf) cancelAnimationFrame(this.epiRaf)

    const draw = () => {
      const t = (performance.now() - start) * 1e-3
      lerpSegments(source, target, t * scale, result)
      if(t < duration) this.epiRaf = requestAnimationFrame(draw)
      else {
        this.epiRaf = 0.
        lerpSegments(source, target, 1.0, result)
      }
      this.sim.syncSimTo(this.curve.segments)
      this.spline.updateControls()
    }

    draw()
  }

  get epiglottis () {
    return this.epiglottisState
  }

  set epiglottis (value) {
    if(this.epiglottisState === value) return

    const source = this.epiglottisState ? this.epiglottisPath : (this.epiHalfState ? this.epiHalfPath : this.defaultPath)
    const target = this.epiglottisState ? (this.epiHalfState ? this.epiHalfPath : this.defaultPath) : this.epiglottisPath

    this.epiglottisState = value

    this.interpolateSpline(source, target, this.curve.segments, this.animationDuration)
  }

  get epiglottisHalf () {
    return this.epiHalfState
  }

  set epiglottisHalf (value) {
    if(this.epiHalfState === value) return

    this.epiHalfState = value
    if(this.epiglottisState) return

    if(value) this.interpolateSpline(this.defaultPath, this.epiHalfPath, this.curve.segments, this.animationDuration)
    else      this.interpolateSpline(this.epiHalfPath, this.defaultPath, this.curve.segments, this.animationDuration)
  }

  get labioDental () {
    return this.labioDentalState
  }

  set labioDental (value) {
    if(this.labioDentalState === value) {
      this.curve.segments[7].point = this.epiInst.epiglottisPoint.subtract(this.jawInst.offset)
      this.spline.updateControls()
      return
    }
    this.labioDentalState = value

    if(this.jawRaf) cancelAnimationFrame(this.jawRaf)

    // While the jaw is translating, move the uvular point.
    const start = performance.now()

    const draw = () => {
      const t = ((performance.now() - start) * 1e-3) / this.animationDuration
      this.curve.segments[7].point = this.epiInst.epiglottisPoint.subtract(this.jawInst.offset)

      if(t < 1.) this.jawRaf = requestAnimationFrame(draw)
      else this.jawRaf = 0.
      this.spline.updateControls()
    }

    draw()
  }

  // Store the current state of the spline as a keyframe
  appendKeyframe (timeStep) {
    this.animator.appendKeyframe(timeStep)
  }

  insertKeyframe (id, timeStep) {
    this.animator.insertKeyframe(id, timeStep)
  }

  updateKeyframe (id, timeStep) {
    this.animator.updateKeyframe(id, timeStep)
  }

  play () {
    if(this.animator.track.length > 1) {
      this.spline.showControls = false
      this.animator.play(1, this.animator.loop, () => {
        this.spline.showControls = true
        this.spline.updateControls()
      })
    }
  }

  onMouseMove (point) {
    if(!this.hasControls) return
    const P = this.jawState ? point.subtract(new paper.Point(5, 42)) : point
    this.spline.move(P)
    if(this.isDragging) this.sim.setFixed(this.dragging, this.curve.segments[this.dragging].point)
  }

  get isDragging () {
    return this.dragging !== -1
  }

  set mode (mode) {
    this.mode_ = mode
    console.log(this.mode_)
    if(mode === 'Bands') {
      this.sim.syncSimTo(this.curve.segments)
    } else if(mode === 'Spline') {
      this.sim.copySim(this.curve.segments)
    }
  }
  get mode () { return this.mode_ }

  onMouseDown (point) {
    if(!this.hasControls) return

    const startP = this.jawInst.jawGroup.position.clone()
    const tx = this.jawInst.jawGroup.globalMatrix.tx
    const ty = this.jawInst.jawGroup.globalMatrix.ty

    const P = this.jawState ? point.subtract(new paper.Point(5, 42)) : point
    this.dragging = this.spline.pick(P)

    if(this.jawInst.jawGroup.position.x !== startP.x || this.jawInst.jawGroup.position.y !== startP.y) {
      this.jawInst.jawGroup.position.set(startP)
      this.jawInst.jawGroup.globalMatrix.tx = tx
      this.jawInst.jawGroup.globalMatrix.ty = ty
    }

    // We start the update cycle and only terminate it after 1 second of no dragging activity, allowing the simulation
    // to set the steady state of the points
    if(this.isDragging && this.mode === 'Bands') {
      const fixed = this.range.filter(e => (e === this.dragging))
      this.sim.addFixed(fixed)

      const updateCycle = () => {
        this.sim.update()
        smooth(this.curve.segments, .1)

        // Synchronise with spline
        this.sim.copySim(this.curve.segments)
        this.spline.updateControls()

        if (this.dragging === -1 && performance.now() - this.lastTime > 100) {
          cancelAnimationFrame(this.tongueRaf)
          this.sim.removeFixed(fixed)
        } else {
          this.tongueRaf = requestAnimationFrame(updateCycle)
        }
      }

      updateCycle()
    }
  }

  onMouseUp (point) {
    if(!this.hasControls) return

    this.dragging = -1
    this.lastTime = performance.now()
    this.spline.release(point)
  }

  setupConstraints(segs) {
    setupConstraints(this.sim, segs)
  }

  buildTongue (colour, width, radius) {
    this.curve = new paper.Path()
    this.curve.visible = true
    this.curve.strokeWidth = width
    this.curve.strokeColor = colour
    this.curve.applyMatrix = false

    this.curve.importJSON(defaultState)

    const segs = this.curve.segments;
    //this.range = buildRange(DEFAULT_TONGUE_STATE.length - 2, 1)
    this.range = buildRange(segs.length)

    this.sim.addSegments(segs) // Only simulate the movable points
    this.setupConstraints(segs) // Constrain all points (the beginning & end are fixed)
    this.sim.addFixed([0, 7]) // The immovable points

    //this.spline = new SplineInput(this.root, this.curve, this.range, radius)
    this.spline = new SplineInput(this.jawInst.jawGroup, this.curve, this.range, radius)
  }

  setToTrack (track) {
    for(let i = 0; i !== track.delta.points.length; ++i) {
      this.curve.segments[i].point.set(track.delta.points[i])
      if(track.delta.handlesIn[i]) this.curve.segments[i].handleIn.set(track.delta.handlesIn[i])
      if(track.delta.handlesOut[i]) this.curve.segments[i].handleOut.set(track.delta.handlesOut[i])
    }

    this.sim.syncSimTo(this.curve.segments)
    this.spline.updateControls()
  }
}