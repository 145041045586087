import { eqPoints, lerpPoints } from '../maths/maths-utils';
import {
  ARTICULATOR_LIMITS_Y,
  ARTICULATOR_POSITIONS_X,
  ARTICULATOR_POSITIONS_Y, DEFAULT_TONGUE_SEGS
} from '../model/defs';
import paper from 'paper';

export function cloneTrack (current) {
  const cp = e => Array.isArray(e) ? new paper.Point(e) : e.clone()

  // Copy all the keys except delta
  const ret = Object.assign({}, current)
  delete ret.delta

    // Copy the delta
  ret.delta = {
    points: current.delta.points.map(cp),
    handlesIn: current.delta.handlesIn.map(cp),
    handlesOut: current.delta.handlesOut.map(cp)
  }

  return ret
}

// Returns the base structure or starting point for the keyframe animation.
export function extractBase (curve) {
  const segs = curve.segments
  const points = new Array(segs.length)
  const handlesIn = new Array(segs.length)
  const handlesOut = new Array(segs.length)
  for(let i = 0; i !== segs.length; ++i) {
    points[i] = segs[i].point.clone()
    handlesIn[i] = segs[i].handleIn.clone()
    handlesOut[i] = segs[i].handleOut.clone()
  }

  return {
    points,
    handlesIn,
    handlesOut
  }
}

// The curveDelta is stored as three arrays with length === segments.length
// If no delta exists, value === null
// Note: Requires a full reference (not a delta!!)
export function curveDelta (curve, reference) {
  const sC = curve.segments

  const refPoints = reference.delta.points
  const refIn = reference.delta.handlesIn
  const refOut = reference.delta.handlesOut

  const count = sC.length

  // For now, we do not support adding or removing segments
  if(count !== refPoints.length) {
    console.error('Size mismatch')
    return null
  }

  const points = new Array(count)
  const handlesIn = new Array(count)
  const handlesOut = new Array(count)

  for(let i = 0; i !== sC.length; ++i) {
    const pC = sC[i].point
    const hIC = sC[i].handleIn
    const hOC = sC[i].handleOut

    points[i] = !eqPoints(pC, refPoints[i]) ? pC.clone() : null
    handlesIn[i] = !eqPoints(hIC, refIn[i]) ? hIC.clone() : null
    handlesOut[i] = !eqPoints(hOC, refOut[i]) ? hOC.clone() : null
  }

  return {
    points,
    handlesIn,
    handlesOut
  }
}

// Interpolates a curve from current to reference for a parameter u = [0 .. 1]
// Both source & target must be snapshots & not delta
export function interpCurve (curve, source, target, u) {
  const count = curve.segments.length

  const srcCount = source.delta.points.length
  if(count !== srcCount || srcCount !== target.delta.points.length) return null

  const srcPoints = source.delta.points
  const srcIn = source.delta.handlesIn
  const srcOut = source.delta.handlesOut

  const tgtPoints = target.delta.points
  const tgtIn = target.delta.handlesIn
  const tgtOut = target.delta.handlesOut

  const segs = curve.segments
  for(let i = 0; i !== count; ++i) {
    // TODO: Change this to avoid the creation of the superfluous point
    segs[i].point.set(tgtPoints[i] ? lerpPoints(srcPoints[i], tgtPoints[i], u) : srcPoints[i])
    segs[i].handleIn.set(tgtIn[i] ? lerpPoints(srcIn[i], tgtIn[i], u) : srcIn[i])
    segs[i].handleOut.set(tgtOut[i] ? lerpPoints(srcOut[i], tgtOut[i], u) : srcOut[i])
  }
}

export function setCurveDelta (curve, delta) {
  const segs = curve.segments
  if(delta.length !== segs.length) return false

  for(let i = 0; i !== segs.length; ++i) {
    const S = segs[i]
    if(delta[0][i] !== null) S.point.set(delta[0][i])
    if(delta[1][i] !== null) S.handleIn.set(delta[1][i])
    if(delta[2][i] !== null) S.handleOut.set(delta[2][i])
  }

  return true
}

// When flipping from one track state to the next, we need to merge the delta on
// the current state
export function mergeDelta (current, target) {
  const count = current.delta.points.length
  if(count !== target.delta.points.length) return

  current.time = target.time
  for(let i = 0; i !== count; ++i) {
    if(target.delta.points[i]) current.delta.points[i].set(target.delta.points[i])
    if(target.delta.handlesIn[i]) current.delta.handlesIn[i].set(target.delta.handlesIn[i])
    if(target.delta.handlesOut[i]) current.delta.handlesOut[i].set(target.delta.handlesOut[i])
  }
}

// Get the articulator positions in world space based on the IPA script
export function getArticulatorEntryOld (obj, name) {
  return [
    ARTICULATOR_POSITIONS_X[obj.tongue[name].x][1],
    ARTICULATOR_POSITIONS_Y[obj.tongue[name].y][1]
  ]
}

// Get the articulator position for x, then use that position to look up the correct steps in y
export function getArticulatorEntry (obj, name) {
  const articulator = obj.tongue[name].x
  const position = obj.tongue[name].y
  if(articulator in ARTICULATOR_LIMITS_Y) {
    const table = ARTICULATOR_LIMITS_Y[articulator]
    return position in table ? [
      ARTICULATOR_POSITIONS_X[articulator][1],
      table[position][1]
    ] : null
  } else {
    return position in ARTICULATOR_POSITIONS_Y ? [
      ARTICULATOR_POSITIONS_X[articulator][1],
      ARTICULATOR_POSITIONS_Y[position][1],
    ]: null
  }
}

// Convert the script into instructions for the spline/jaw, returns Javascript types not Paper points
// If delta is passed and no instruction is
export function createTrack (obj, time, delta) {
  const SEGS = DEFAULT_TONGUE_SEGS

  // Rule, if no point is in the set and the delta is true, no point is emitted, if delta is not true, the default
  // tongue segs are emitted
  const points = [
    delta ? null : SEGS[0][0],
    obj?.tongue?.['undertip'] ? getArticulatorEntry(obj, 'undertip') : delta ? null : SEGS[1][0],
    obj?.tongue?.['tip'] ? getArticulatorEntry(obj, 'tip') : delta ? null : SEGS[2][0],
    obj?.tongue?.['blade'] ? getArticulatorEntry(obj, 'blade') : delta ? null : SEGS[3][0],
    obj?.tongue?.['front_or_middle'] ? getArticulatorEntry(obj, 'front_or_middle') : delta ? null : SEGS[4][0],
    obj?.tongue?.['back'] ? getArticulatorEntry(obj, 'back') : delta ? null : SEGS[5][0],
    obj?.tongue?.['root'] ? getArticulatorEntry(obj, 'root') : delta ? null : SEGS[6][0],
    delta ? null : SEGS[7][0]
  ]


  const handlesIn = SEGS.map(e => delta ? null : e[1])
  const handlesOut = SEGS.map(e => delta ? null : e[2])

  return {
    time,
    voice: obj.voice ?? false,
    glottis: obj.glottis ?? false,
    lips: obj.lips ?? false,
    velum: obj.velum ?? false,
    mouth: obj.mouth ?? false,
    jaw: obj.jaw ?? false,
    epiglottis: obj.epiglottis ?? false,
    epiHalf: obj.epiHalf ?? false,
    labioDental: obj.labioDental ?? false,
    aspiration: obj.aspiration ?? false,
    airstream: obj.airstream ?? false,
    airstreamNose: obj.airstreamNose ?? false,
    airstreamMouth: obj.airstreamMouth ?? false,
    delta: {
      points: points.map(e => e ? new paper.Point(e) : null),
      handlesIn: handlesIn.map(s => s ? new paper.Point(s) : null),
      handlesOut: handlesOut.map(s => s ? new paper.Point(s) : null)
    }
  }
}

// Setup the simulator constraints by taking the distance between each joint in the default tongue model and
// Allow compression of 25% and stretching of 50%
export function setupConstraints (sim, segs) {
  const count = segs.length
  for(let i = 1; i !== count; ++i) {
    const len = segs[i-1].point.subtract(segs[i].point).length
    sim.addConstraint(i-1, i, 0.3, len - len/4, len + len/2)
  }
}

// Get the index of values in a delta array that are set (i.e. not null)
export function changedIndex (segs) {
  const lst = []
  for(let i = 0; i !== segs.length; ++i) {
    if(segs[i] != null) lst.push(i)
  }
  return lst
}

// We need a parseTrack function that takes the JSON format and converts it back into a track
export function parseTrack (track) {
  const data = JSON.parse(track)

  for(let i = 0; i !== data.length; ++i) {
    const d = data[i]
    d.delta = {}
    if(d.data) {
      d.delta.points = d.data.points.map(e => e ? new paper.Point(e) : null)
      d.delta.handlesIn = d.data.handlesIn.map(e => e ? new paper.Point(e) : null)
      d.delta.handlesOut = d.data.handlesOut.map(e => e ? new paper.Point(e) : null)
      delete d.data
    }
  }

  return data
}