/*
Controls interpolation between one keyframe and another.
*/

import Verlet from '../maths/verlet'
import {
  DEFAULT_TONGUE_SEGS,
} from '../model/defs';
import {
  cloneTrack,
  interpCurve,
  mergeDelta,
  extractBase,
  createTrack,
  setupConstraints,
  changedIndex
} from './track';
import { smooth } from "../model/splineInput";
import {execute} from "paper";

const STOPPED = 0
const PLAYING = 1

export default class KeyframeAnimator {
  constructor (root, track) {
    this.root = root
    this.state = STOPPED
    this.time = 0
    this.track = track ?? [] // contains { time: time_offset, curveDelta: [ [ point, handleIn, handleOut ], ... ] }
    this.source = null // Source Interpolation frame (always a complete frame)
    this.target = null // Target Interpolation frame (delta/complete frame)
    this.loop_ = false
    this.trackDefCB_ = null
  }

  set structures (defs) {
    this.tongue = defs.tongue
    this.jaw = defs.jaw
    this.glottis = defs.glottis
    this.lips = defs.lips
    this.velum = defs.velum
    this.epiglottis = defs.epiglottis
    this.airstream = defs.airstreams
  }

  get duration () {
    return this.track[this.track.length - 1].time
  }

  set tongue (tongue) {
    this.tongue_ = tongue
    this.curve_ = tongue.curve
    this.segments = tongue.curve.segments
  }

  // The tongue curve
  set curve (curve) {
    this.curve_ = curve
    this.segments = curve.segments
  }

  set loop (value) { this.loop_ = value }
  get loop () { return this.loop_ }

  // This is the callback that is called when the track definition is changed
  set trackDefCB (cb) { this.trackDefCB_ = cb }
  get trackDefCB () { return this.trackDefCB_ }

  set trackData (track) {
    this.track = track
    this.setStates(this.track[0], 0.5)
    if(this.track.length > 1) {
      interpCurve(this.curve_, cloneTrack(this.track[0]), this.track[1], 0.)
      this.tongue_.spline.updateControls()
    }
  }

  set jaw (jaw) { this.jaw_ = jaw }
  set glottis (glottis) { this.glottis_ = glottis }
  set lips (lips) { this.lips_ = lips }
  set velum (velum) { this.velum_ = velum }
  set epiglottis (epiglottis) { this.epiglottis_ = epiglottis }
  set airstream (airstream) { this.airstream_ = airstream }

  // Sets the current state of the animation to the start of the first track
  rewind () {
    this.time = 0
    this.current = cloneTrack(this.track[0])
    interpCurve(this.curve_, this.current, this.current, 0)

    this.tongue_.spline.updateControls()
    this.tongue_.sim.syncSimTo(this.tongue_.curve.segments)
    this.tongue_.spline.showControls = true
  }

  setStates (track, delta) {
    if(delta != null) {
      this.glottis_.aniDuration = delta
      this.lips_.aniDuration = delta
      this.velum_.aniDuration = delta
      this.jaw_.aniDuration = delta
      this.tongue_.aniDuration = delta
      this.epiglottis_.aniDuration = delta
    }

    this.glottis_.voice = track.voice ?? false
    this.glottis_.glottis = track.glottis ?? false
    this.lips_.extended = track.lips ?? false
    this.velum_.extended = track.velum ?? false
    this.lips_.mouth = track.mouth ?? false
    this.lips_.labioDental = track.labioDental ?? false
    this.jaw_.labioDental = track.labioDental ?? false
    this.jaw_.open = track.jaw ?? false
    this.tongue_.labioDental = track.labioDental ?? false
    this.tongue_.jaw = track.jaw ?? false
    this.epiglottis_.extended = track.epiglottis ?? false
    this.epiglottis_.halfClosed = track.epiHalf ?? false
    this.glottis_.aspiration = track.aspiration ?? false
    this.airstream_.airstream = track.airstream ?? false
    this.airstream_.airstreamNose = track.airstreamNose ?? false
    this.airstream_.airstreamMouth = track.airstreamMouth ?? false

    if(track.epiglottis) {
      this.velum_.extended = true
      this.tongue_.epiglottis = true
    } else {
      this.tongue_.epiglottis = false
    }
    if(track.epiHalf) {
      this.velum_.halfClosed = true
      this.tongue_.epiglottisHalf = true
    } else {
      this.velum_.halfClosed = false
      this.tongue_.epiglottisHalf = false
    }
  }

  stop () {
    this.state = STOPPED
    cancelAnimationFrame(this.raf)
    this.setStates(this.track[0], 0.5)
    if(this.track.length > 1) {
      interpCurve(this.curve_, cloneTrack(this.track[0]), this.track[1], 0.)
      this.tongue_.spline.updateControls()
    }
  }

  play (scale=1.0, loop=false, cb) {
    // Always reset for now
    if(this.track.length < 2) {
      console.log('nothing to play...')
      return
    }

    let track = []
    for(let i = 0; i !== this.track.length; ++i) {
      track.push(cloneTrack(this.track[i]))
    }

    let source = cloneTrack(track[0])
    let target = track[1]
    let currTrack = 0
    let endTime = track[this.track.length-1].time

    let t = 0
    let curr = performance.now() * .001 // convert to seconds
    let prev = performance.now() * .001
    let dt = 0

    this.setStates(target, target.time - source.time)

    // If we are looping, put the first track at the end of the track
    if(loop) {
      track.push(cloneTrack(track[0]))
      let back = track[track.length-1]
      back.time = endTime + (endTime - track[track.length-3].time)
      endTime = back.time
    }

    const draw = () => {
      prev = curr
      curr = performance.now() * .001
      dt = curr - prev
      t += scale * dt

      if(t < target.time) {
        let u = (t - source.time)/(target.time - source.time)
        interpCurve(this.curve_, source, target, u)
        this.raf = requestAnimationFrame(draw)
      } else {
        // First set the curve to the current target time.
        interpCurve(this.curve_, source, target, 1.)
        if(target.time < endTime) {
          mergeDelta(source, target)
          currTrack += 1

          target = track[currTrack + 1]
          this.setStates(target, target.time - source.time)

          this.raf = requestAnimationFrame(draw)
        } else if(loop) {
          source = cloneTrack(track[0])

          target = track[1]
          this.setStates(target, target.time - source.time)

          t = 0
          currTrack = 0
          this.raf = requestAnimationFrame(draw)
        } else {
          this.setStates(target, null)
          cb?.()
        }
      }
    }

    this.state = PLAYING
    draw()
  }

  appendKeyframe (advance) {
    if(this.track.length === 0) { // Use the current definition
      this.track.push({
        time: 0,
        delta: extractBase(this.curve_),
        voice: this.glottis_.voice,
        lips: this.lips_.extended,
        velum: this.velum_.extended,
        mouth: this.lips_.mouth,
        jaw: this.jaw_.open,
        labioDental: this.jaw_.labioDental,
        glottis: this.glottis_.glottis,
        epiglottis: this.epiglottis_.extended,
        epiHalf: this.epiglottis_.halfClosed,
        aspiration: this.glottis_.aspiration,
        airstream: this.airstream_.airstream,
        airstreamNose: this.airstream_.airstreamNose,
        airstreamMouth: this.airstream_.airstreamMouth
      })
      // Initialise
      this.current = cloneTrack(this.track[0])
    } else {
      // Compute the delta between the current definition of the curve and the current state
      this.track.push({
        time: this.time,
        delta: extractBase(this.curve_), //curveDelta(this.curve_, this.current),
        voice: this.glottis_.voice,
        lips: this.lips_.extended,
        velum: this.velum_.extended,
        mouth: this.lips_.mouth,
        jaw: this.jaw_.open,
        labioDental: this.jaw_.labioDental,
        glottis: this.glottis_.glottis,
        epiglottis: this.epiglottis_.extended,
        epiHalf: this.epiglottis_.halfClosed,
        aspiration: this.glottis_.aspiration,
        airstream: this.airstream_.airstream,
        airstreamNose: this.airstream_.airstreamNose,
        airstreamMouth: this.airstream_.airstreamMouth
      })
    }

    this.time += advance
  }

  // This function updates the time sequence from the starting id with the new time delta.  All subsequent tracks have
  // to be updated as well as they use the start time rather than delta time
  updateTimeSequence (id, timestep) {
    if(id == null || id >= this.track.length) return
    id = Number(id)

    let curr = 0
    const deltas = this.track.map(e => {
      const d = e.time - curr
      curr = e.time
      return d
    })

    if(timestep != null) deltas[id] = timestep

    let time = id === 0 ? 0 : this.track[id-1].time
    for(let i = id; i !== this.track.length; ++i) {
      time += deltas[i]
      this.track[i].time = time
    }
  }

  updateKeyframe (id, timestep) {
    if(this.track.length > id) {
      const track = this.track[id]
      track.delta = extractBase(this.curve_)
      track.voice = this.glottis_.voice
      track.lips = this.lips_.extended
      track.velum = this.velum_.extended
      track.mouth = this.lips_.mouth
      track.glottis = this.glottis_.glottis
      track.jaw = this.jaw_.open
      track.labioDental = this.jaw_.labioDental
      track.epiglottis = this.epiglottis_.extended
      track.epiHalf = this.epiglottis_.halfClosed
      track.aspiration = this.glottis_.aspiration
      track.airstream = this.airstream_.airstream
      track.airstreamNose = this.airstream_.airstreamNose
      track.airstreamMouth = this.airstream_.airstreamMouth
      this.updateTimeSequence(id, timestep)
      if(this.trackDefCB_) this.trackDefCB_()
    } else {
      console.error('Invalid track id:', id)
    }
  }

  insertKeyframe (id, timeStep) {
    id = Number(id)
    if(id < this.track.length) {
      this.track = [
          ...this.track.slice(0, id),
        {
          time: id * timeStep,
          delta: extractBase(this.curve_),
          voice: this.glottis_.voice,
          lips: this.lips_.extended,
          velum: this.velum_.extended,
          mouth: this.lips_.mouth,
          jaw: this.jaw_.open,
          labioDental: this.jaw_.labioDental,
          glottis: this.glottis_.glottis,
          epiglottis: this.epiglottis_.extended,
          epiHalf: this.epiglottis_.halfClosed,
          aspiration: this.glottis_.aspiration,
          airstream: this.track[id].airstream,
          airstreamNose: this.track[id].airstreamNose,
          airstreamMouth: this.track[id].airstreamMouth

        },
        ...this.track.slice(id)
      ]

      for(let i = 0; i < this.track.length; ++i) {
        this.track[i].time = i * timeStep
      }
    }
  }

  deleteKeyframe (time) {
    const idx = this.track.findIndex(e => e.time === time)
    if(idx !== -1) {
      this.track = this.track.splice(idx, 1)
    }
  }

  deleteTrack (idx) {
    if(idx < this.track.length) {
      this.track = [
        ...this.track.slice(0, idx),
        ...this.track.slice(idx+1)
      ]
    }
  }

  getState () {
    const arr = []
    for(let i = 0; i !== this.track.length; ++i) {
      arr.push({
        time: this.track[i].time,
        data: {
          points: this.track[i].delta.points.map(e => e ? [e.x, e.y] : null),
          handlesIn: this.track[i].delta.handlesIn.map(e => e ? [e.x, e.y] : null),
          handlesOut: this.track[i].delta.handlesOut.map(e => e ? [e.x, e.y] : null),
        },
        voice: this.track[i].voice,
        glottis: this.track[i].glottis,
        lips: this.track[i].lips,
        velum: this.track[i].velum,
        mouth: this.track[i].mouth,
        jaw: this.track[i].jaw,
        labioDental: this.track[i].labioDental,
        epiglottis: this.track[i].epiglottis,
        epiHalf: this.track[i].epiHalf,
        aspiration: this.track[i].aspiration,
        airstream: this.track[i].airstream,
        airstreamNose: this.track[i].airstreamNose,
        airstreamMouth: this.track[i].airstreamMouth
      })
    }

    return JSON.stringify(arr)
  }

  getTrackState (id) {
    if(id < this.track.length) {
      const track = {
        time: this.track[id].time,
        data: {
          points: this.track[id].delta.points.map(e => e ? [e.x, e.y] : null),
          handlesIn: this.track[id].delta.handlesIn.map(e => e ? [e.x, e.y] : null),
          handlesOut: this.track[id].delta.handlesOut.map(e => e ? [e.x, e.y] : null),
        },
        voice: this.track[id].voice,
        glottis: this.track[id].glottis,
        lips: this.track[id].lips,
        velum: this.track[id].velum,
        mouth: this.track[id].mouth,
        jaw: this.track[id].jaw,
        labioDental: this.track[id].labioDental,
        epiglottis: this.track[id].epiglottis,
        epiHalf: this.track[id].epiHalf,
        aspiration: this.track[id].aspiration,
        airstream: this.track[id].airstream,
        airstreamNose: this.track[id].airstreamNose,
        airstreamMouth: this.track[id].airstreamMouth
      }
      return JSON.stringify(track)
    }
    return ''
  }

  getTrack (id) {
    return this.track[id]
  }

  // Executes a script of the following form:
  /*
  Each row is an object containing
  script: [
    {"jaw":false,"tongue":{"back":{"x":"palatal_soft","y":"cave_up_touch"},"tip":{"x":"alveolar","y":"cave_low_mid"}}},
    {"jaw":true,"tongue":{"back":{"x":"palatal_soft","y":"cave_up_touch"},"tip":{"x":"alveolar","y":"cave_low_mid"}}}
  ]
  */
  executeScript (script, timeStep, playbackSpeed=1.0, neutralTrack=false, loop=false) {
    if(script == null || script.length < 3) return

    // Check if the format is in Ruby's hash format and if so, convert it to JSON
    const scriptCnv = script.replace(/=>/g, ':')

    this.track = []
    this.time = 0
    const interval = timeStep

    const SEGS = DEFAULT_TONGUE_SEGS
    const sim = new Verlet(SEGS.length, SEGS.length-1)

    for (let i = 0; i !== SEGS.length; ++i) {
      this.segments[i].point.set(SEGS[i][0])
      this.segments[i].handleIn.set(SEGS[i][1])
      this.segments[i].handleOut.set(SEGS[i][2])
    }

    // Initialise the sim
    sim.addSegments(this.segments)
    setupConstraints(sim, this.segments)
    sim.syncSimTo(this.segments)

    let arr = null

    try {
      arr = JSON.parse(scriptCnv)
    } catch(e) {
      console.warn('Failed to parse JSON:', scriptCnv, e);
      return
    }

    this.track.push(createTrack({jaw:false, tongue:{}}, 0.0, false))
    this.time += interval

    for (let i = 0; i !== arr.length; ++i) {
      const track = createTrack(arr[i], this.time, this.track.length > 0)
      sim.clearFixed()
      let fixed = [...changedIndex(track.delta.points)]
      sim.addFixed(fixed)

      sim.syncToTrack(track)
      for(let j = 0; j !== 10; ++j) {
        sim.update()
        sim.copySim(this.segments)

        smooth(this.segments)

        sim.syncSimTo(this.segments)
      }
      const updatedTrack = sim.updateTrackFromSim(track)

      this.track.push(updatedTrack)
      this.time += interval
    }

    if(!neutralTrack && this.track.length > 1) {
      const value = this.track.shift()
      for(let i = 0; i !== this.track.length; ++i) {
        this.track[i].time = i * interval
        for(let j = 0; j !== this.track[i].delta.handlesIn.length; ++j) {
          this.track[i].delta.handlesIn[j] = new paper.Point(value.delta.handlesIn[j])
          this.track[i].delta.handlesOut[j] = new paper.Point(value.delta.handlesOut[j])
        }
      }
    }

    const startTime = performance.now()
    this.tongue_.spline.showControls = false
    if(playbackSpeed > 0) {
      this.play(playbackSpeed, loop, () => {
        //console.log('Playback of Simulation:', Number((performance.now() - startTime) * .001).toFixed(3), 'seconds')
        sim.copySim(this.tongue_.curve.segments)
        this.tongue_.spline.updateControls()
        this.tongue_.sim.syncSimTo(this.tongue_.curve.segments)
        this.tongue_.spline.showControls = true
      })
    } else {
      sim.copySim(this.tongue_.curve.segments)
        this.tongue_.spline.updateControls()
        this.tongue_.sim.syncSimTo(this.tongue_.curve.segments)
        this.tongue_.spline.showControls = true
    }
  }
}
