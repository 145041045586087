import paper from 'paper'
import { lerpSegments } from "../../maths/maths-utils"

const velumDefaultState = `["Path",{"applyMatrix":true,"segments":[[[284.99811,523.11378],[-5.13884,-0.08544],[18.50447,0.30765]],[[323.3,527.6],[-12.28763,-3.13061],[23,5.8]],[[393.8,564.2],[-20.2,-16.7],[16.5,13.6]],[[424,625.5],[-4.9,-29.9],[3.4,20.8]],[[419.4,656.4],[6.8,-1.7],[-5,1.3]],[[400.5,631.4],[11.7,23.3],[-13.2,-26.3]],[[370.1,583.9],[10.2,10.2],[-15.5,-15.6]],[[303.8,550.9],[25.8,5],[-5.47957,-1.05257]],[[284.99897,549.0352],[6.82599,0.20052],[-15.2232,-0.44719]]]}]`
const velumHalfState = `["Path",{"applyMatrix":true,"segments":[[[284.99811,523.11378],[-5.13884,-0.08544],[18.50447,0.30765]],[[339.45434,528.06405],[-13.82316,-2.41856],[20.45924,3.75326]],[[399.44891,554.0434],[-16.92609,-12.6163],[13.36957,9.78642]],[[428.05435,594.07927],[-7.14293,-18.78967],[5.11304,13.81305]],[[425.90109,630.00753],[7.66631,-1.27663],[-7.19293,1.50326]],[[400.23913,599.58905],[18.64891,16.34294],[-11.71956,-14.85652]],[[364.56087,569.01296],[11.07283,6.37989],[-13.72282,-9.07989]],[[304.95978,549.95318],[22.28587,3.35326],[-8.28598,-0.95291]],[[284.99897,549.0352],[6.82599,0.20052],[-15.2232,-0.44719]]]}]`
const velumClosedState = `["Path",{"applyMatrix":true,"segments":[[[284.99811,523.11378],[-5.13884,-0.08544],[18.50447,0.30765]],[[355.60868,528.52809],[-15.3587,-1.70652],[17.91848,1.70652]],[[405.09782,543.88679],[-13.65218,-8.53261],[10.23913,5.97283]],[[432.10869,562.65853],[-9.38587,-7.67935],[6.82609,6.82609]],[[432.40217,603.61506],[8.53261,-0.85326],[-9.38587,1.70652]],[[399.97825,567.7781],[25.59783,9.38587],[-10.23913,-3.41304]],[[359.02173,554.12592],[11.94565,2.55978],[-11.94565,-2.55978]],[[306.11955,549.00636],[18.77174,1.70652],[-11.09239,-0.85326]],[[284.99897,549.0352],[6.82599,0.20052],[-15.2232,-0.44719]]]}]`

export default class Velum {
  constructor (root, { colour, width }) {
    this.root = root

    this.animationDuration = .5
    this.raf = 0.

    // These are our two transition states
    const A = new paper.Path({ visible: false })
    A.importJSON(velumDefaultState)
    this.startState = A.segments

    const B = new paper.Path({ visible: false })
    B.importJSON(velumHalfState)
    this.halfState = B.segments

    const C = new paper.Path({ visible: false })
    C.importJSON(velumClosedState)
    this.endState = C.segments

    this.velum = new paper.Path()
    this.velum.importJSON(velumDefaultState)

    this.velum.strokeColor = colour ?? 'black'
    this.velum.strokeWidth = width ?? 1
    this.root.addChild(this.velum)

    this.extended_ = false
    this.halfClosed_ = false
  }

  interpolateSpline (source, target, result, duration) {
    let raf = 0
    const start = performance.now()
    const scale = 1./duration

    const draw = () => {
      const t = (performance.now() - start) * 1e-3
      lerpSegments(source, target, t * scale, result)
      if(t < duration) raf = requestAnimationFrame(draw)
      else lerpSegments(source, target, 1.0, result)
    }

    draw()
  }

  get aniDuration () {
    return this.animationDuration
  }

  set aniDuration (value) {
    this.animationDuration = value
  }

  get extended () { return this.extended_ }
  set extended (val) {
    if(this.extended_ === val) return

    this.extended_ = val

    const source = val ? this.startState : this.endState
    const target = val ? this.endState : this.startState

    this.interpolateSpline(source, target, this.velum.segments, this.animationDuration)
  }

  get halfClosed () { return this.halfClosed_ }
  set halfClosed (val) {
    if(this.halfClosed_ === val) return

    this.halfClosed_ = val
    if(this.extended_) return

    const source = val ? this.startState : this.halfState
    const target = val ? this.halfState : this.startState

    this.interpolateSpline(source, target, this.velum.segments, this.animationDuration)
  }
}