/*
Handles segment definitions and animations for the voice or glottis settings
*/

import paper from 'paper'
import {createSineWave, createCup, updateSpline, applySineWave, updateWaveSpline} from '../../maths/geometry'
import {lerpSegments} from "../../maths/maths-utils";

/*
We have five different states:

1) No glottis, voice, or aspiration
2) Glottis + no voice
3) Glottis + voice
4) aspiration + no voice
5) aspiration + voice
*/


export default class Glottis {
  constructor (root, { colour, width }) {
    this.voiceState = true
    this.glottisState = true

    colour = colour ?? 'black'
    width = width ?? 1

    this.animationDuration = .5
    this.voiceRaf = 0
    this.leftRaf = { value: 0. }
    this.rightRaf = { value: 0. }

    // Setup new animation splines for voice box
    this.voiceGroup = new paper.Group()

    const leftTop = new paper.Point([409.5, 822])
    const leftBottom = new paper.Point([420, 862])

    const deflection = 1.12

    this.leftSpline = createCup(leftTop, leftBottom, 0, 10)
    this.leftStartState = createCup(leftTop, leftBottom, 0, 10)
    this.leftStartState.visible = false
    this.leftHEndState = createCup(leftTop, leftBottom, -deflection*.6, 10)
    this.leftHEndState.visible = false
    this.leftEndState = createCup(leftTop, leftBottom, -deflection, 10)
    this.leftEndState.visible = false
    //this.leftWave = createSineWave(leftTop, leftBottom, 40, 6)
    this.leftWave = applySineWave(this.leftSpline, 1.5, 8, 50)

    this.leftSpline.strokeColor = colour
    this.leftWave.strokeColor = colour

    this.voiceGroup.addChild(this.leftSpline)
    this.voiceGroup.addChild(this.leftWave)
    this.leftWave.visible = false

    const rightTop = new paper.Point([453.5, 794])
    const rightBottom = new paper.Point([462, 843])

    // Each end state requires a duplicate state for the voice, the sine wave vibration is mapped to the wall or glottis

    this.rightSpline = createCup(rightTop, rightBottom, 0, 10)
    this.rightStartState = createCup(rightTop, rightBottom, 0, 10)
    this.rightStartState.visible = false
    this.rightHEndState = createCup(rightTop, rightBottom, deflection*.6, 10)
    this.rightHEndState.visible = false
    this.rightEndState = createCup(rightTop, rightBottom, deflection, 10)
    this.rightEndState.visible = false
    //this.rightWave = createSineWave(rightTop, rightBottom, 40, 6)
    this.rightWave = applySineWave(this.rightSpline, 1.5, 8, 50)

    this.rightSpline.strokeColor = colour
    this.rightWave.strokeColor = colour

    this.voiceGroup.addChild(this.rightSpline)
    this.voiceGroup.addChild(this.rightWave)
    this.rightWave.visible = false

    root.addChild(this.voiceGroup)

    this.root = root

    this.voiceState = false
    this.glottisState = false
    this.glottis = false
    this.aspiration_ = false
  }

  get aniDuration () {
    return this.animationDuration
  }

  set aniDuration (value) {
    this.animationDuration = value
  }

  // Make the vocal splines animate by vibrating the top and bottom paths
  // Dampened sine wave between top start and end segment and bottom start and end segments
  set voice (value) {
    if(this.voiceState === value) return

    this.voiceState = value

    // When the vocal chords are active, we replace the left and right spline by their wave-altered forms
    if(value) {
      const paint = () => {
        this.voiceRaf = requestAnimationFrame(paint)
        updateWaveSpline(this.leftWave, this.leftSpline, 0.16)
        updateWaveSpline(this.rightWave, this.rightSpline, 0.16)
      }
      paint()
      this.leftWave.visible = true
      this.rightWave.visible = true
      this.leftSpline.visible = false
      this.rightSpline.visible = false
    } else {
      cancelAnimationFrame(this.voiceRaf)
      this.leftWave.visible = false
      this.rightWave.visible = false
      this.leftSpline.visible = true
      this.rightSpline.visible = true
    }
  }

  get voice () {
    return this.voiceState
  }

  interpolateSpline (raf, source, target, result, duration) {
    const start = performance.now()
    const scale = 1./duration

    if(raf.value) cancelAnimationFrame(raf.value)

    const draw = () => {
      const t = (performance.now() - start) * 1e-3
      lerpSegments(source, target, t * scale, result)
      if(t < duration) raf.value = requestAnimationFrame(draw)
      else {
        lerpSegments(source, target, 1.0, result)
        raf.value = 0.
      }
    }

    draw()
  }

  set glottis (value) {
    if(this.glottisState === value) return

    this.glottisState = value

    const sourceLeft = value ? this.leftStartState.segments : this.leftEndState.segments
    const targetLeft = value ? this.leftEndState.segments : this.leftStartState.segments

    const sourceRight = value ? this.rightStartState.segments : this.rightEndState.segments
    const targetRight = value ? this.rightEndState.segments : this.rightStartState.segments

    this.interpolateSpline(this.leftRaf, sourceLeft, targetLeft, this.leftSpline.segments, this.animationDuration)
    this.interpolateSpline(this.rightRaf, sourceRight, targetRight, this.rightSpline.segments, this.animationDuration)
  }

  get glottis () {
    return this.glottisState
  }

  set aspiration (value) {
    if(this.aspiration_ === value) return

    this.aspiration_ = value

    const sourceLeft = value ? this.leftStartState.segments : this.leftHEndState.segments
    const targetLeft = value ? this.leftHEndState.segments : this.leftStartState.segments

    const sourceRight = value ? this.rightStartState.segments : this.rightHEndState.segments
    const targetRight = value ? this.rightHEndState.segments : this.rightStartState.segments

    this.interpolateSpline(this.leftRaf, sourceLeft, targetLeft, this.leftSpline.segments, this.animationDuration)
    this.interpolateSpline(this.rightRaf, sourceRight, targetRight, this.rightSpline.segments, this.animationDuration)
  }

  get aspiration () {
    return this.aspiration_;
  }
}
