/*
Animates the airstream toggle settings
*/

import paper from 'paper'
import { createScaledSineWave, updateScaledSpline } from '../../maths/geometry'

export default class Airstreams {
  constructor (root, { colour, width }) {
    this.root = root

    this.jaw_ = false
    this.airstream_ = false
    this.airstreamNose_ = false
    this.airstreamMouth_ = false

    this.group = new paper.Group()
    this.initialiseMouth()
    this.initialiseNose()
    root.addChild(this.group)
  }

  initialiseMouth () {
    // Shift the mouth airstreams to the right by 100 pixels in the direction of flow

    const startPoints = [
        { x: 165, y: 622 },
        { x: 165, y: 633 },
        { x: 166, y: 644 }
    ]

    const endPoints = [
        { x: 78, y: 602 },
        { x: 82, y: 644 },
        { x: 92, y: 686 }
    ]

    const delta = [
        { x: endPoints[0].x - startPoints[0].x, y: endPoints[0].y - startPoints[0].y },
        { x: endPoints[1].x - startPoints[1].x, y: endPoints[1].y - startPoints[1].y },
        { x: endPoints[2].x - startPoints[2].x, y: endPoints[2].y - startPoints[2].y }
    ]

    const normalised = [
        { x: delta[0].x / Math.sqrt(delta[0].x * delta[0].x + delta[0].y * delta[0].y), y: delta[0].y / Math.sqrt(delta[0].x * delta[0].x + delta[0].y * delta[0].y) },
        { x: delta[1].x / Math.sqrt(delta[1].x * delta[1].x + delta[1].y * delta[1].y), y: delta[1].y / Math.sqrt(delta[1].x * delta[1].x + delta[1].y * delta[1].y) },
        { x: delta[2].x / Math.sqrt(delta[2].x * delta[2].x + delta[2].y * delta[2].y), y: delta[2].y / Math.sqrt(delta[2].x * delta[2].x + delta[2].y * delta[2].y) }
      ]

    const scale = 20.0

    const transformedStart = [
        { x: startPoints[0].x + scale * normalised[0].x, y: startPoints[0].y + scale * normalised[0].y },
        { x: startPoints[1].x + scale * normalised[1].x, y: startPoints[1].y + scale * normalised[1].y },
        { x: startPoints[2].x + scale * normalised[2].x, y: startPoints[2].y + scale * normalised[2].y }
    ]

    const transformedEnd = [
        { x: endPoints[0].x + scale * normalised[0].x, y: endPoints[0].y + scale * normalised[0].y },
        { x: endPoints[1].x + scale * normalised[1].x, y: endPoints[1].y + scale * normalised[1].y },
        { x: endPoints[2].x + scale * normalised[2].x, y: endPoints[2].y + scale * normalised[2].y }
      ]

    this.mouthLine0 = new paper.Path.Line(transformedStart[0], transformedEnd[0])
    this.mouthLine0.visible = false
    this.mouthLine0r = new paper.Path.Line(transformedEnd[0], transformedStart[0])
    this.mouthLine0r.visible = false

    this.mouthLine1 = new paper.Path.Line(transformedStart[1], transformedEnd[1])
    this.mouthLine1.visible = false
    this.mouthLine1r = new paper.Path.Line(transformedEnd[1], transformedStart[1])
    this.mouthLine1r.visible = false

    this.mouthLine2 = new paper.Path.Line(transformedStart[2], transformedEnd[2])
    this.mouthLine2.visible = false
    this.mouthLine2r = new paper.Path.Line(transformedEnd[2], transformedStart[2])
    this.mouthLine2r.visible = false

    this.mouthAirstreamOut0 = createScaledSineWave(this.mouthLine0, 6, 6, 50)
    this.mouthAirstreamOut1 = createScaledSineWave(this.mouthLine1, 6, 6, 50)
    this.mouthAirstreamOut2 = createScaledSineWave(this.mouthLine2, 6, 6, 50)
    this.mouthOutGroup = new paper.Group()
    this.mouthOutGroup.addChild(this.mouthAirstreamOut0)
    this.mouthOutGroup.addChild(this.mouthAirstreamOut1)
    this.mouthOutGroup.addChild(this.mouthAirstreamOut2)
    this.mouthOutGroup.visible = false

    this.mouthAirstreamIn0 = createScaledSineWave(this.mouthLine0r, 6, 6, 50)
    this.mouthAirstreamIn1 = createScaledSineWave(this.mouthLine1r, 6, 6, 50)
    this.mouthAirstreamIn2 = createScaledSineWave(this.mouthLine2r, 6, 6, 50)
    this.mouthInGroup = new paper.Group()
    this.mouthInGroup.addChild(this.mouthAirstreamIn0)
    this.mouthInGroup.addChild(this.mouthAirstreamIn1)
    this.mouthInGroup.addChild(this.mouthAirstreamIn2)
    this.mouthInGroup.visible = false

    this.group.addChild(this.mouthOutGroup)
    this.group.addChild(this.mouthInGroup)
  }

  initialiseNose () {
    this.noseLine0 = new paper.Path.Line({x: 121.35, y: 525.42}, {x: 75.31, y: 580.89})
    this.noseLine0.visible = false
    this.noseLine0r = new paper.Path.Line({x: 75.31, y: 580.89}, {x: 121.35, y: 525.42})
    this.noseLine0r.visible = false

    this.noseLine1 = new paper.Path.Line({x: 121.78, y: 529.69}, {x: 108.83, y: 590.66})
    this.noseLine1.visible = false
    this.noseLine1r = new paper.Path.Line({x: 108.83, y: 590.66}, {x: 121.78, y: 529.69})
    this.noseLine1r.visible = false

    this.noseLine2 = new paper.Path.Line({x: 122.24, y: 525.43}, {x: 138.45, y: 595.40})
    this.noseLine2.visible = false
    this.noseLine2r = new paper.Path.Line({x: 138.45, y: 595.40}, {x: 122.24, y: 525.43})
    this.noseLine2r.visible = false
    
    this.noseAirstreamOut0 = createScaledSineWave(this.noseLine0, 6, 6, 50)
    this.noseAirstreamOut1 = createScaledSineWave(this.noseLine1, 6, 6, 50)
    this.noseAirstreamOut2 = createScaledSineWave(this.noseLine2, 6, 6, 50)
    this.noseOutGroup = new paper.Group()
    this.noseOutGroup.addChild(this.noseAirstreamOut0)
    this.noseOutGroup.addChild(this.noseAirstreamOut1)
    this.noseOutGroup.addChild(this.noseAirstreamOut2)
    this.noseOutGroup.visible = false

    this.noseAirstreamIn0 = createScaledSineWave(this.noseLine0r, 6, 6, 50)
    this.noseAirstreamIn1 = createScaledSineWave(this.noseLine1r, 6, 6, 50)
    this.noseAirstreamIn2 = createScaledSineWave(this.noseLine2r, 6, 6, 50)
    this.noseInGroup = new paper.Group()
    this.noseInGroup.addChild(this.noseAirstreamIn0)
    this.noseInGroup.addChild(this.noseAirstreamIn1)
    this.noseInGroup.addChild(this.noseAirstreamIn2)
    this.noseInGroup.visible = false

    this.group.addChild(this.noseOutGroup)
    this.group.addChild(this.noseInGroup)
  }

  setNoseState (enabled, exhale) {
    cancelAnimationFrame(this.outNoseRaf)
    cancelAnimationFrame(this.inNoseRaf)

    if (enabled) {
        if (!exhale) {
          this.noseInGroup.visible = false
          this.noseOutGroup.visible = true
          const paint = () => {
            updateScaledSpline(this.noseAirstreamOut0, this.noseLine0, 0.016)
            updateScaledSpline(this.noseAirstreamOut1, this.noseLine1, 0.016)
            updateScaledSpline(this.noseAirstreamOut2, this.noseLine2, 0.016)
            this.outNoseRaf = requestAnimationFrame(paint)
          }
          paint()
        } else {  // Inhale
          this.noseOutGroup.visible = false
          this.noseInGroup.visible = true
          const paint = () => {
            updateScaledSpline(this.noseAirstreamIn0, this.noseLine0r, 0.016)
            updateScaledSpline(this.noseAirstreamIn1, this.noseLine1r, 0.016)
            updateScaledSpline(this.noseAirstreamIn2, this.noseLine2r, 0.016)
            this.inNoseRaf = requestAnimationFrame(paint)
          }
          paint()
        }
    } else {  // Turn nose airstream off
        this.noseOutGroup.visible = false
        this.noseInGroup.visible = false
    }
  }

  setMouthState (enabled, exhale) {
    cancelAnimationFrame(this.inMouthRaf)
    cancelAnimationFrame(this.outMouthRaf)

    if (enabled) {
        if (!exhale) {
            this.mouthInGroup.visible = false
            this.mouthOutGroup.visible = true
            const paint = () => {
                updateScaledSpline(this.mouthAirstreamOut0, this.mouthLine0, 0.016)
                updateScaledSpline(this.mouthAirstreamOut1, this.mouthLine1, 0.016)
                updateScaledSpline(this.mouthAirstreamOut2, this.mouthLine2, 0.016)
                this.outMouthRaf = requestAnimationFrame(paint)
            }
            paint()
        } else {  // Inhale
            this.mouthOutGroup.visible = false
            this.mouthInGroup.visible = true
            const paint = () => {
                updateScaledSpline(this.mouthAirstreamIn0, this.mouthLine0r, 0.016)
                updateScaledSpline(this.mouthAirstreamIn1, this.mouthLine1r, 0.016)
                updateScaledSpline(this.mouthAirstreamIn2, this.mouthLine2r, 0.016)
                this.inMouthRaf = requestAnimationFrame(paint)
            }
            paint()
        }
    } else {  // Turn mouth airstream off
        this.mouthOutGroup.visible = false
        this.mouthInGroup.visible = false
    }
  }

  // The states seem quite complex so I've built a state table to map the states to the correct actions
  /*
  Airstream Mouth, Airstream Nose, Airstream, Jaw
              off,            off,       off, off        - nothing (Slide 1)
              off,            off,       off,  on        - nothing
               on,            off,       off, off        - nothing
               on,            off,       off,  on        - mouth inhale
               on,             on,       off, off        - nothing (Slide 2)
               on,             on,       off,  on        - mouth inhale, nose inhale
              off,             on,       off, off        - nose inhale
              off,             on,       off,  on        - nose inhale
              off,            off,        on, off        - none (Slide 3)
              off,            off,        on,  on        - none
               on,            off,        on, off        - none
               on,            off,        on,  on        - mouth exhale
               on,             on,        on, off        - indeterminate (Slide 4)
               on,             on,        on,  on        - mouth exhale, nose exhale
              off,             on,        on, off        - nose exhale
              off,             on,        on,  on        - indeterminate

  */

  setStates (mouthState, noseState, airstream, jawState) {
    const jaw = 1 << 0
    const air = 1 << 1
    const nose = 1 << 2
    const mouth = 1 << 3

    const state = (airstream ? air : 0) | (noseState ? nose : 0) | (mouthState ? mouth : 0) | (jawState ? jaw : 0)

    switch(state) {
      case 0b0000: // off, off, off, off
        this.setNoseState(false, false)
        this.setMouthState(false, false)
        break
      case 0b0001: // off, off, off, on
        this.setNoseState(false, false)
        this.setMouthState(false, false)
        break
      case 0b0010: // off, off, on, off
        this.setNoseState(false, false)
        this.setMouthState(false, false)
        break
      case 0b0011: // off, off, on, on
        this.setNoseState(false, false)
        this.setMouthState(false, false)
        break
      case 0b0100: // off, on, off, off
        this.setNoseState(true, false) // NOSE INHALE
        this.setMouthState(false, false)
        break
      case 0b0101: // off, on, off, on
        this.setNoseState(true, false) // NOSE INHALE
        this.setMouthState(false, false)
        break
      case 0b0110: // off, on, on, off
        this.setNoseState(true, true) // NOSE EXHALE
        this.setMouthState(false, false)
        break
      case 0b0111: // off, on, on, on
        this.setNoseState(true, true) // NOSE EXHALE - indeterminate
        this.setMouthState(false, false)
        break
      case 0b1000: // on, off, off, off
        this.setNoseState(false, false)
        this.setMouthState(false, false)
        break
      case 0b1001: // on, off, off, on
        this.setNoseState(false, false)
        this.setMouthState(true, false) // MOUTH INHALE
        break
      case 0b1010: // on, off, on, off
        this.setNoseState(false, false)
        this.setMouthState(false, false)
        break
      case 0b1011: // on, off, on, on
        this.setNoseState(false, false)
        this.setMouthState(true, true) // MOUTH EXHALE
        break
      case 0b1100: // on, on, off, off
        this.setNoseState(false, false)
        this.setMouthState(false, false)
        break
      case 0b1101: // on, on, off, on
        this.setNoseState(true, false) // NOSE INHALE
        this.setMouthState(true, false) // MOUTH INHALE
        break
      case 0b1110: // on, on, on, off
        this.setNoseState(true, true) // NOSE EXHALE  - indeterminate
        this.setMouthState(false, false)
        break
      case 0b1111: // on, on, on, on
        this.setNoseState(true, true) // NOSE EXHALE
        this.setMouthState(true, true) // MOUTH EXHALE
        break
      default:
        this.setNoseState(false, false)
        this.setMouthState(false, false)
        break
    }
  }

  get jaw () { return this.jaw_ }
  set jaw (val) {
    if(this.jaw_ === val) return
    this.jaw_ = val
    this.setStates(this.airstreamMouth_, this.airstreamNose_, this.airstream_, this.jaw_)
  }

  // Controls the direction of airflow, off = in, on = out
  get airstream () { return this.airstream_ }
  set airstream (val) {
    if(this.airstream_ === val) return
    this.airstream_ = val
    this.setStates(this.airstreamMouth_, this.airstreamNose_, this.airstream_, this.jaw_)
  }

  // Nose airstream either in or out when on
  get airstreamNose () { return this.airstreamNose_ }
  set airstreamNose (val) {
    if(this.airstreamNose_ === val) return
    this.airstreamNose_ = val
    this.setStates(this.airstreamMouth_, this.airstreamNose_, this.airstream_, this.jaw_)
  }

  // Mouth airstream either in or out when on
  get airstreamMouth () { return this.airstreamMouth_ }
  set airstreamMouth (val) {
    if(this.airstreamMouth_ === val) return
    this.airstreamMouth_ = val
    this.setStates(this.airstreamMouth_, this.airstreamNose_, this.airstream_, this.jaw_)
  }
}


