/*
The base model for the head and articulators.  The model contains the necessary code to interact with and render the
cross-section on the screen.  It contains the definitions of all the rendered objects and as such, can be thought of
as the scene graph root.

Notes:

1) The original path definition is converted into screen space
*/

import paper from 'paper'
import {
  ARTICULATOR_LIMITS_Y,
  ARTICULATOR_POSITIONS_X,
  BUILD_GUIDELINES,
  HIGHLIGHT_SPLIT_POINT,
  SHOW_CONTROLS,
  ZOOMED_IN
} from './defs'
import Extractor, { extractAnimationPaths } from './extraction'
import { distSq } from '../maths/maths-utils'
import { Model } from './structures/model'
import {parseTrack} from "../animation/track";

export default class Controller {
  constructor (root, showControls=true) {
    this.root = root
    this.timeStep_ = 0.5
    this.showControls_ = showControls

    this.models = []
    this.models[0] = new Model('#000000ff', 0, showControls ?? SHOW_CONTROLS)

    this.root.addChild(this.models[0].group)
    this.models[0].setupHandlers()

    // You can translate the black background model in the x axis slightly using the second parameter below
    this.models[1] = new Model('#ff00007f', 0, false)
    this.root.addChild(this.models[1].group)
    this.models[1].visible = false

    //this.initialiseDebug()

    // Convert mouse input into local model space
    paper.view.onMouseMove = ev => this.onMouseMove(ev)
    paper.view.onMouseDown = ev => this.onMouseDown(ev)
    paper.view.onMouseUp = ev => this.onMouseUp(ev)

    this.root.fitBounds(paper.view.bounds)

    if(ZOOMED_IN) {
      this.root.scale(2)
      this.root.translate([200, -200])
    }

    if(BUILD_GUIDELINES) this.buildGuideLines()
    if(HIGHLIGHT_SPLIT_POINT) this.buildSplitPointUI()

    this.setupHandlers()

    this.models[0].jaw.open = true
    this.models[0].jaw.open = false
  }

  // Debug Visualisations
  initialiseDebug () {
    this.mouseP = new paper.Path.Circle({
      center: [0, 0],
      radius: 4,
      fillColor: 'pink'
    })
    this.mouseP.visible = false
    this.root.addChild(this.mouseP)
  }

  get timeStep () { return this.timeStep_ }
  set timeStep (value) {
    this.timeStep_ = value
    if(this.trackId) {
      this.models[0].animator.updateKeyframe(this.trackId, this.timeStep)
      this.updateTrackList()
    }
  }

  onMouseMove (ev) {
    const tongue = this.models[0].tongue

    const P = tongue.curve.globalToLocal(ev.point)
    //this.mouseP.position = tongue.curve.getNearestPoint(P)

    if(tongue) {
      const P = this.root.globalToLocal(ev.point)
      tongue.onMouseMove(P)
    }

    if(this.extractor) this.extractor.updateSplitPoint(P)
  }

  // Split Point UI (for breaking up curves - or rather for finding the points at which we need to split the curve).
  buildSplitPointUI () {
    const head = this.models[0].head
    this.extractor = new Extractor(this.root, head)

    extractAnimationPaths() // Run this temporarily from here for testing

    const p = new paper.Path.Circle({
      center: [0, 0],
      radius: 5,
      strokeColor: 'green'
    })
    p.name = 'splitpoint'

    this.root.addChild(p)
  }

  onMouseUp (ev) {
    const P = this.root.globalToLocal(ev.point)

    if(this.indexPoint !== -1) {
      this.indexPoint = -1
      this.pickPoint = undefined
    }

    const tongue = this.models[0].tongue

    if(tongue) {
      tongue.onMouseUp(P)
      //console.log(this.trackId)
      if(this.trackId) {
        tongue.updateKeyframe(this.trackId)
      }
    }
  }

  onMouseDown (ev) {
    // Spline Control Point Picking
    const P = this.root.globalToLocal(ev.point)

    if(this.smoothPoints) {
      //console.log(this.smoothPoints)
      for(let i = 0; i !== this.smoothPoints.length/2; ++i) {
        const p = this.smoothPoints.slice(2*i, 2*(i+1))
        const d = distSq({x: p[0], y: p[1]}, ev.point)
        //console.log('dist:', d)
        if(d < 50) {
          //console.log('selected:', i)
          this.pickPoint = p
          this.indexPoint = i
        }
      }
    }

    const tongue = this.models[0].structures.tongue

    if(tongue) tongue.onMouseDown(P)
    if(this.extractor) this.extractor.displaySplitPoint()
  }

  setupPlaybackMode () {
    // Get the track-data from the track-data paragraph element - Pass via Ruby?
    const track_data = document.getElementById('track-data')
    const trackData = track_data.innerText.length === 0 ? data : track_data.innerText

    // Note: The data here *was* escaped twice, now it's gone!!
    const text = JSON.parse(trackData)
    this.models[0].animator.trackData = parseTrack(text)

    const playBtn = document.getElementById('play')
    const loopBtn = document.getElementById('loop')
    const stopBtn = document.getElementById('stop')

    playBtn.addEventListener('click', () => {
      this.models[0].tongue.play()
    })

    stopBtn.addEventListener('click', () => {
      this.models[0].animator.stop()
    })

    loopBtn.addEventListener('click', () => {
      this.models[0].loop = !this.models[0].loop
    })
  }

  setupHandlers () {
    // Only bound for model 0 for now

    if(!this.showControls_) {
      // This function set up the handlers for the playback of the animation
      console.log('Setting up playback mode')
      this.setupPlaybackMode()
      return
    }

    const {
      glottis,
      lips,
      velum,
      tongue,
      jaw,
      epiglottis,
      airstreams
    } = this.models[0].structures
    const animator = this.models[0].animator

    document.getElementById('modelDisplay0').addEventListener('click', () => {
      this.models[0].visible = true
      this.models[1].visible = false
    })

    this.textOutput = document.getElementById('state-output')

    animator.trackDefCB = () => {
      this.textOutput.value = animator.getState()
    }

    if(this.textOutput) {
      const text = this.textOutput.value

      if(text.length > 0) {
        // Parse the track and set the KeyframeAnimator track
        const track = parseTrack(text)
        const trackList = document.getElementById('track-list')
        for(let i = 0; i !== track.length; ++i) {
          trackList.add(new Option(`Track ${i}: ${Number(track[i].time).toFixed(3)}s`, i))
        }

        animator.trackData = track
      }
    }

    document.getElementById('capture').addEventListener('click', () => {
      if(this.trackId) tongue.insertKeyframe(this.trackId, 0.5)
      else tongue.appendKeyframe(this.timeStep)
      this.textOutput.value = animator.getState()
      this.updateTrackList()
    })

    // Setup IPA input for model 0
    const ipa = document.getElementById('ipa')

    const neutralTrack = document.getElementById('neutral-track')
    if(neutralTrack) {
      neutralTrack.addEventListener('click', () => {
        this.models[0].animator.executeScript(ipa.value, this.timeStep, 1.0, neutralTrack.checked, animator.loop)
        this.models[0].animator.rewind()
      })
    }

    document.getElementById('run').addEventListener('click', () => {
      animator.executeScript(ipa.value, this.timeStep, 1.0, neutralTrack.checked, animator.loop)
      this.textOutput.value = animator.getState()
      this.updateTrackList()
    })

    const loopBtn = document.getElementById('loop')
    if(loopBtn) {
      loopBtn.addEventListener('click', () => {
        this.models[0].loop = !this.models[0].loop
      })
    }

    // Setup Run for model 0
    const playBtn = document.getElementById('play')
    playBtn.addEventListener('click', () => {
      this.models[0].tongue.play()
    })

    const stopBtn = document.getElementById('stop')
    stopBtn.addEventListener('click', () => {
      this.models[0].animator.stop()
    })

    // Setup Track List & Time Adjustment
    const timeStep = document.getElementById('timestep')
    const trackList = document.getElementById('track-list')
    trackList.addEventListener('change', (ev) => {
      this.trackId = ev.target.value
      const prevTime = this.trackId > 0 ? animator.getTrack(this.trackId-1).time : 0.0
      this.models[0].trackId = this.trackId

      this.textOutput.value = animator.getState()

      // Set the tongue to the state and allow any changes to update the state
      const track = animator.getTrack(this.trackId)
      timeStep.value = track.time - prevTime
      tongue.setToTrack(track)
      jaw.open = track.jaw
      tongue.jaw = track.jaw
      tongue.labioDental = track.labioDental
      jaw.labioDental = track.labioDental
      lips.labioDental = track.labioDental
      lips.extended = track.lips
      lips.mouth = track.mouth
      velum.extended = track.velum
      glottis.voice = track.voice
      glottis.glottis = track.glottis
      epiglottis.extended = track.epiglottis

      // TODO: Add to track
      airstreams.airstream = track.airstream
      airstreams.airstreamNose = track.airstreamNose
      airstreams.airstreamMouth = track.airstreamMouth

      if(track.epiglottis) {
        velum.extended = true
        tongue.epiglottis = true
      } else {
        tongue.epiglottis = false
      }
      epiglottis.halfClosed = track.epiHalf
      if(track.epiHalf) {
        velum.halfClosed = true
        tongue.epiglottisHalf = true
      } else {
        velum.halfClosed = false
        tongue.epiglottisHalf = false
      }
      glottis.aspiration = track.aspiration

      document.getElementById('voice').checked = track.voice
      document.getElementById('glottis').checked = track.glottis
      document.getElementById('lips').checked = track.lips
      document.getElementById('velum').checked = track.velum
      document.getElementById('mouth').checked = track.mouth
      document.getElementById('jaw').checked = track.jaw
      document.getElementById('epiglottis').checked = track.epiglottis
      document.getElementById('epi_half').checked = track.epiHalf
      document.getElementById('aspiration').checked = track.aspiration
      document.getElementById('labio_dental').checked = track.labioDental
      document.getElementById('airstream').checked = track.airstream
      document.getElementById('airstream_nose').checked = track.airstreamNose
      document.getElementById('airstream_mouth').checked = track.airstreamMouth
    })

    // Implement delete-track button
    const deleteTrack = document.getElementById('delete-track')
    deleteTrack.addEventListener('click', () => {
        if(this.trackId && this.models[0].animator.track.length > 1) {
            this.trackId = this.models[0].animator.deleteTrack(this.trackId)
            this.updateTrackList()
        }
    })

    timeStep.addEventListener('change', ev => this.timeStep = Number(ev.target.value))
    if(!this.models[0].animator.trackData?.length && this.textOutput.value === '') {
      this.models[0].animator.executeScript(ipa.value, this.timeStep, 1.0, neutralTrack.checked, animator.loop)
      this.updateTrackList()
    }
    this.models[0].animator.rewind()
  }

  buildBounds (paths) {
    for(let i = 0; i !== paths.length; ++i) {
      this.root.addChild(buildBounds(paths[i]))
    }
  }

  updateTrackList () {
    const trackList = document.getElementById('track-list')

    const track = this.models[0].animator.track
    for(let i = trackList.options.length-1; i > -1; --i) trackList.remove(i)

    for(let i = 0; i !== track.length; ++i) {
      trackList.add(new Option(`Track ${i}: ${Number(track[i].time).toFixed(3)}s`, i))
    }
  }

  // Builds the guide lines/points for visualisation
  buildGuideLines() {
    // Add guidelines so that we can visualise what's going on...
    this.guides = new paper.Group()
    // X coords
    const xkeys = Object.keys(ARTICULATOR_POSITIONS_X)
    for (let i = 0; i !== xkeys.length; ++i) {
      const l = new paper.Path.Line({
        from: [ARTICULATOR_POSITIONS_X[xkeys[i]][1], 0],
        to: [ARTICULATOR_POSITIONS_X[xkeys[i]][1], 1000],
        strokeColor: '#00ff0040'
      })
      this.guides.addChild(l)
    }

    // For each horizontal control point, draw a short segment showing the position
    const artKeys = Object.keys(ARTICULATOR_LIMITS_Y)
    for (let a = 0, aKey = artKeys[0]; a !== artKeys.length; ++a, aKey = artKeys[a]) {
      const obj = ARTICULATOR_LIMITS_Y[aKey]
      const vkeys = Object.keys(obj)
      const horzS = ARTICULATOR_POSITIONS_X[aKey][1] - 4, horzE = horzS + 8
      for (let v = 0, vKey = vkeys[0]; v !== vkeys.length; ++v, vKey = vkeys[v]) {
        const h = obj[vkeys[v]][1]
        const l = new paper.Path.Line({
          from: [horzS, h],
          to: [horzE, h],
          strokeColor: '#ff000060'
        })
        this.guides.addChild(l)
      }
    }

    this.root.addChild(this.guides)
  }
}
