import paper from 'paper'
import {lerpSegments, perp} from '../../maths/maths-utils.js'
import {bottom} from "@popperjs/core";

const lipsTopDef = `["Path",{"applyMatrix":true,"segments":[[[190.43,572.048],[0.17668,0.34237],[-0.05362,-0.10391]],[[190.3,572.5],[0.04657,-0.39582],[-3.3,24.2]],[[180.7,608.7],[4.5,-5.2],[-3.8,4.3]],[[166.9,615],[5.7,0],[-8.8,0]],[[152,601.6],[0,8],[0,-3.6]],[[157.2,587.3],[-4.1,7.6],[0,0]],[162.493,572.238]]}]`
const lipsTopExtendedDef = `["Path",{"applyMatrix":true,"segments":[[[190.43,572.048],[-0.01818,0.38484],[0.00552,-0.1168]],[[189.50019,580.48494],[0.23824,-0.31951],[-14.95788,19.30781]],[[163.08634,607.03506],[6.49711,-2.25333],[-5.4409,1.82391]],[[147.98519,605.59102],[4.93634,2.85],[-7.62102,-4.4]],[[141.78142,586.53628],[-4,6.9282],[1.8,-3.11769]],[[153.43475,576.75211],[-7.3507,4.53179],[0,0]],[162.493,572.238]]}]`
const lipsBottomDef = `["Path",{"applyMatrix":true,"segments":[[[170.38955,672.46701],[0.00699,0.50611],[-0.16906,-12.24571]],[[162.9,640.9],[5.08965,9.8912],[-4.2,-8.4]],[[161.1,622.6],[-3,3.8],[5.3,-6.7]],[[187,616.8],[-7.3,-3.8],[4.1,2.2]],[[198.6,634.7],[-2.2,-7.6],[1.3,4.6]],[[199,663.2],[1,-20.2],[-0.15677,3.37921]],[[198.6132,672.07201],[0.10057,-2.5596],[-0.47679,12.135]]]}]`
const lipsBottomExtendedDef = `["Path",{"applyMatrix":true,"segments":[[[170.38955,672.46701],[0.25911,0.43481],[-6.26927,-10.52057]],[[152.44481,656.08071],[9.35337,6.02121],[-7.83731,-5.17461]],[[141.73597,641.13245],[-0.69808,4.7909],[1.23993,-8.45237]],[[161.26603,623.1595],[-8.22199,0.3591],[4.6507,-0.14474]],[[180.26192,632.86135],[-5.70526,-5.48179],[3.42583,3.33372]],[[194.85833,657.34308],[-9.23397,-17.99371],[1.55384,3.00487]],[[198.6132,672.07201],[-1.1927,-2.26696],[5.65459,10.74761]]]}]`
const lipsBottomLabioDentalDef = `["Path",{"applyMatrix":true,"segments":[[[170.38955,672.46701],[-0.0729,0.5496],[1.76396,-13.29793]],[[168.68476,636.84023],[2.96526,11.68721],[-2.40979,-9.90188]],[[169.94936,616.67223],[-3.25286,3.54346],[5.74463,-6.24566]],[[193.81167,615.33639],[-5.87631,-5.51089],[3.29012,3.16639]],[[201.29559,636.943],[-0.76217,-8.65322],[0.43332,5.23144]],[[197.19604,667.89313],[4.04325,-21.69142],[-0.66706,3.63071]],[[198.6132,672.07201],[0.10057,-2.5596],[-0.47679,12.135]]]}]`

export default class Lips {
  constructor (root, jawRoot, { colour, width }) {
    this.root = root
    this.jawRoot = jawRoot

    this.animationDuration = .5
    this.raf = {
      mouth: 0.,
      extended: 0.
    }

    colour = colour ?? 'black'
    width = width ?? 1

    this.extendedState = false
    this.mouthState = false
    this.labioDentalState = false

    this.top = [[190.430, 572.048], [162.493, 572.238]]
    this.bottom = [[170.38955,672.46701], [198.6132,672.07201]]

    const bottomState = new paper.Path({visible: false})
    bottomState.importJSON(lipsBottomDef)
    const bottomExState = new paper.Path({visible: false})
    bottomExState.importJSON(lipsBottomExtendedDef)
    const bottomLabioDentalState = new paper.Path({visible: false})
    bottomLabioDentalState.importJSON(lipsBottomLabioDentalDef)
    this.bottomStartState = bottomState.segments
    this.bottomEndState = bottomExState.segments
    this.bottomLabioDentalState = bottomLabioDentalState.segments

    const topState = new paper.Path({visible: false})
    topState.importJSON(lipsTopDef)
    const topExState = new paper.Path({visible: false})
    topExState.importJSON(lipsTopExtendedDef)
    this.topStartState = topState.segments
    this.topEndState = topExState.segments

    this.lipsTop = new paper.Path()
    this.lipsTop.importJSON(lipsTopDef)
    this.lipsTop.strokeColor = colour
    this.lipsTop.strokeWidth = width
    this.lipsTop.applyMatrix = false
    this.root.addChild(this.lipsTop)

    this.lipsBottom = new paper.Path()
    this.lipsBottom.importJSON(lipsBottomDef)
    this.lipsBottom.strokeColor = colour
    this.lipsBottom.strokeWidth = width
    this.lipsBottom.applyMatrix = false
    this.jawRoot.addChild(this.lipsBottom)
  }

  get aniDuration () {
    return this.animationDuration
  }

  set aniDuration (value) {
    this.animationDuration = value
  }

  get extended () { return this.extendedState }

  // Sets the extended state (bottom and top lip extended)
  set extended (val) {
    if(this.extendedState === val) return

    this.extendedState = val

    if(this.mouthState !== this.extendedState) this.mouth = val

    if(this.raf.extended) cancelAnimationFrame(this.raf.extended)

    const start = performance.now()

    if(val) {
      const draw = () => {
        const t = (performance.now() - start) * 1e-3
        lerpSegments(this.topStartState, this.topEndState, t/this.animationDuration, this.lipsTop.segments)
        if(t < this.animationDuration) this.raf.extended = requestAnimationFrame(draw)
        else this.raf.extended = 0.
      }
      draw()
    } else {
      const draw = () => {
        const t = (performance.now() - start) * 1e-3
        lerpSegments(this.topEndState, this.topStartState, t/this.animationDuration, this.lipsTop.segments)
        if(t < this.animationDuration) this.raf.extended = requestAnimationFrame(draw)
        else this.raf.extended = 0.
      }
      draw()
    }
  }

  get mouth () { return this.mouthState }

  // Sets the mouth state (lower lip extended only)
  set mouth (val) {
    if(this.mouthState === val) return

    this.mouthState = val

    const start = performance.now()

    if(this.raf.mouth) cancelAnimationFrame(this.raf.mouth)

    if(val) {
      const draw = () => {
        const t = (performance.now() - start) * 1e-3
        lerpSegments(this.bottomStartState, this.bottomEndState, t/this.animationDuration, this.lipsBottom.segments)
        if(t < this.animationDuration) this.raf.mouth = requestAnimationFrame(draw)
        else this.raf.mouth = 0.
      }
      draw()
    } else {
      const draw = () => {
        const t = (performance.now() - start) * 1e-3
        lerpSegments(this.bottomEndState, this.bottomStartState, t/this.animationDuration, this.lipsBottom.segments)
        if(t < this.animationDuration) this.raf.mouth = requestAnimationFrame(draw)
        else this.raf.mouth = 0.
      }
      draw()
    }
  }

  get labioDental () {
    return this.labioDentalState
  }

  // Sets the lower lip to touch the bottom teeth
  set labioDental (val) {
    if(this.labioDentalState === val) return

    this.labioDentalState = val

    const start = performance.now()

    if(this.raf.labioDental) cancelAnimationFrame(this.raf.labioDental)

    if(val) {
        const draw = () => {
            const t = (performance.now() - start) * 1e-3
            lerpSegments(this.bottomStartState, this.bottomLabioDentalState, t/this.animationDuration, this.lipsBottom.segments)
            if(t < this.animationDuration) this.raf.labioDental = requestAnimationFrame(draw)
            else this.raf.labioDental = 0.
        }
        draw()
    } else {
        const draw = () => {
            const t = (performance.now() - start) * 1e-3
            lerpSegments(this.bottomLabioDentalState, this.bottomStartState, t/this.animationDuration, this.lipsBottom.segments)
            if(t < this.animationDuration) this.raf.labioDental = requestAnimationFrame(draw)
            else this.raf.labioDental = 0.
        }
        draw()
    }
  }
}