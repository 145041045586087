/*
This class stores the model state for the curves and data that make up a visual display of the model.
Abstracted from the original definition to allow overlaying of data

Notes:

1) Create a single model group that we can use to remove the entire model contents from the root group.
2) Display the static head separately so we don't have to keep copying it.
*/

import { Group } from 'paper'
import KeyframeAnimator from '../../animation/KeyframeAnimator'
import { loadModels } from '../geometry'
import Tongue from './tongue'
import Glottis from './glottis'
import Lips from './lips'
import Jaw from './newJaw'
import Head from './head'
import Velum from './velum'
import Verlet from '../../maths/verlet'
import Epiglottis from './epiglottis'
import Airstreams from './airstreams'

export class Model {
  constructor (colour, translateX=0, showControls) {
    const group = new Group()
    this.group_ = group
    group.applyMatrix = false
    this.showControls_ = showControls
    this.group_.position.set([translateX, 0])

    this.sim = new Verlet(8, 7)
    this.animator_ = new KeyframeAnimator(this.group)

    const {
      head,
      closedJaw,
      tongue
    } = loadModels()

    const conf = { colour, width: 1 }

    const jaw = new Jaw(group, closedJaw, conf)
    const epiglottis = new Epiglottis(group, conf)
    const airstreams = new Airstreams(group, conf)

    const structures = {
      head: new Head(group, jaw.jawGroup, conf),
      tongue: new Tongue(group, tongue, this.sim, conf, showControls, jaw, epiglottis),
      jaw,
      glottis: new Glottis(group, conf),
      lips: new Lips(group, jaw.jawGroup, conf),
      velum: new Velum(group, conf),
      epiglottis,
      airstreams
    }

    this.structures = structures
    this.animator_.structures = structures
    this.structures.tongue.animator = this.animator

    this.loop_ = false

    this.handlers = { }
  }

  setupHandlers () {
    const {
      glottis,
      lips,
      velum,
      tongue,
      jaw,
      epiglottis,
      airstreams
    } = this.structures

    if (!this.showControls_) return

    const animator = this.animator

    const voiceToggle = document.getElementById('voice')
    const vtCB = ev => {
      glottis.voice = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    voiceToggle.addEventListener('click', vtCB)
    this.handlers['voiceToggle'] = [voiceToggle, 'click', vtCB]

    const glottisToggle = document.getElementById('glottis')
    const gtCB = ev => {
      glottis.glottis = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    glottisToggle.addEventListener('click', gtCB)
    this.handlers['glottisToggle'] = [glottisToggle, 'click', gtCB]

    const lipsToggle = document.getElementById('lips')
    const ltCB = ev => {
      lips.extended = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    lipsToggle.addEventListener('click', ltCB)
    this.handlers['lipsToggle'] = [lipsToggle, 'click', ltCB]

    const velumToggle = document.getElementById('velum')
    const etCB = ev => {
      velum.extended = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    velumToggle.addEventListener('click', etCB)
    this.handlers['lipsToggle'] = [velumToggle, 'click', etCB]

    const mouthToggle = document.getElementById('mouth')
    const mtCB = ev => {
      lips.mouth = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    mouthToggle.addEventListener('click', mtCB)
    this.handlers['mouthToggle'] = [mouthToggle, 'click', mtCB]

    const labioDentalToggle = document.getElementById('labio_dental')
    const ldtCB = ev => {
      lips.labioDental = ev.target.checked // We need to move the lip inwards
      jaw.labioDental = ev.target.checked // We need to slightly open the jaw and move it back a bit
      tongue.labioDental = ev.target.checked // The tongue needs the state of the jaw in order to correctly interpolate
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    labioDentalToggle.addEventListener('click', ldtCB)
    this.handlers['labioDentalToggle'] = [labioDentalToggle, 'click', ldtCB]

    const jawToggle = document.getElementById('jaw')
    const jtCB = (ev) => {
      jaw.open = ev.target.checked
      tongue.jaw = ev.target.checked
      airstreams.jaw = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    jawToggle.addEventListener('click', jtCB)
    this.handlers['jawToggle'] = [jawToggle, 'click', jtCB]

    const epiglottisToggle = document.getElementById('epiglottis')
    const egCB = (ev) => {
      epiglottis.extended = ev.target.checked
      velum.extended = ev.target.checked
      tongue.epiglottis = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    epiglottisToggle.addEventListener('click', egCB)
    this.handlers['epiglottisToggle'] = [epiglottisToggle, 'click', egCB]

    const epiHalfToggle = document.getElementById('epi_half')
    const ehCB = (ev) => {
      epiglottis.halfClosed = ev.target.checked
      velum.halfClosed = ev.target.checked
      tongue.epiglottisHalf = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    epiHalfToggle.addEventListener('click', ehCB)
    this.handlers['epiHalfToggle'] = [epiHalfToggle, 'click', ehCB]

    const aspirationToggle = document.getElementById('aspiration')
    const asCB = (ev) => {
      glottis.aspiration = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    aspirationToggle.addEventListener('click', asCB)
    this.handlers['aspirationToggle'] = [aspirationToggle, 'click', asCB]

    // Setup Airstreams toggles
    const airstreamToggle = document.getElementById('airstream')
    const airsCB = (ev) => {
      airstreams.airstream = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    airstreamToggle.addEventListener('click', airsCB)
    this.handlers['airstreamToggle'] = [airstreamToggle, 'click', airsCB]

    const airstreamNoseToggle = document.getElementById('airstream_nose')
    const airnCB = (ev) => {
      airstreams.airstreamNose = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    airstreamNoseToggle.addEventListener('click', airnCB)
    this.handlers['airstreamNoseToggle'] = [airstreamNoseToggle, 'click', airnCB]

    const airstreamMouthToggle = document.getElementById('airstream_mouth')
    const airmCB = (ev) => {
      airstreams.airstreamMouth = ev.target.checked
      if(this.trackId) animator.updateKeyframe(this.trackId, this.timeStep)
    }
    airstreamMouthToggle.addEventListener('click', airmCB)
    this.handlers['airstreamMouthToggle'] = [airstreamMouthToggle, 'click', airmCB]

    const bandsControl = document.getElementById('spline')
    const bcCB = (ev) => {
      if(ev.target.checked) {
        tongue.mode = 'Bands'
      } else {
        tongue.mode = 'Spline'
      }
    }
    bandsControl.addEventListener('click', bcCB)
    this.handlers['bandsControl'] = [bandsControl, 'click', bcCB]
  }

  removeHandlers () {
    const keys = Object.keys(this.handlers)
    for(let i = 0; i !== keys.length; ++i) {
      const [obj, event, cb] = this.handlers[keys[i]]
      obj.removeEventListener(event, cb)
    }
  }

  set loop (value) {
    this.loop_ = value
    this.animator_.loop = value
  }

  get loop () {
    return this.loop_
  }

  get visible () {
    return this.group_.visible
  }

  set visible (value) {
    this.group_.visible = value
  }

  get group () {
    return this.group_
  }

  get head () { return this.structures.head }
  get tongue () { return this.structures.tongue }
  get jaw () { return this.structures.jaw }
  get glottis () { return this.structures.glottis }
  get lips () { return this.structures.lips }
  get velum () { return this.structures.velum }
  get epiglottis () { return this.structures.epiglottis }
  get airstreams () { return this.structures.airstreams }
  get animator () { return this.animator_ }
}