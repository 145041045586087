/*
The simulator allows the input of several joints, the tension between them, and the elastic constraints used to
influence neighbouring joints.

Constraints:

{ start, end, elastic, minLength, maxLength }
// start - seg (uint32)
// end - seg (uint32)
// elastic - coefficient (float32)
// minLength - after which the constraint acts as a rod (float32)
// maxLength - after which the constraint acts as a rope (float32)
// size = 5 * 4 = 20 bytes per constraint
*/

import paper from 'paper'

const CONSTRAINT_SIZE = 20
const CON_A = 0
const CON_B = 1
const CON_ELASTIC = 0
const CON_MIN = 1
const CON_MAX = 2

// Directly in array
function calcForce(buffer, A, B, k, restLen) {
  const dX = buffer[B] - buffer[A]
  const dY = buffer[B+1] - buffer[A+1]
  const len = Math.sqrt(dX*dX + dY*dY)
  return [
    dX * (-k * (len - restLen) / len),
    dY * (-k * (len - restLen) / len)
  ]
}

// 6 free joints, 2 fixed joints, 7 constraints
export default class Verlet {
  constructor (maxJoints, maxConstraints) {
    this.maxJoints = maxJoints
    this.maxConstraints = maxConstraints
    this.jointCount = 0
    this.constraintCount = 0
    this.fixedList = [] // The list of points that are fixed (in relation to the jaw)

    this.curr = new Float32Array(2 * this.maxJoints) // individual joints current position
    this.prev = new Float32Array(2 * this.maxJoints) // individual joints previous position
    this.delta = new Float32Array(2 * this.maxJoints) // current frame delta

    this.constraintBuffer = new ArrayBuffer(this.maxConstraints * CONSTRAINT_SIZE)
    this.constraints = [] // Constraint objects
  }

  // We automatically set the joint
  addSegments (segs) {
    if(segs.length > (this.maxJoints - this.jointCount)) return false

    this.segs = segs

    for(let i = 0, idx = 0; i !== segs.length; ++i, idx += 2) {
      const s = [segs[i].point.x, segs[i].point.y]
      this.curr.set(s, idx)
      this.prev.set(s, idx)
    }

    this.jointCount += segs.length
    return true
  }

  // Used to translate the entire simulation (when the jaw state changes)
  translateWorld (offset) {
    for(let i = 0, iX = 0, iY = 1; i !== this.jointCount; ++i, iX += 2, iY += 2) {
      this.curr[iX] += offset.x; this.curr[iY] += offset.y
      this.prev[iX] += offset.x; this.prev[iY] += offset.y
    }
  }

  setFixed (idx, point) {
    if(2*idx < this.curr.length) {
      const iX = 2*idx, iY = iX + 1
      this.curr[iX] = this.prev[iX] = point.x
      this.curr[iY] = this.prev[iY] = point.y
    }
  }

  addFixed (lst) {
    this.fixedList = [...this.fixedList, ...lst]
  }

  removeFixed (lst) {
    this.fixedList = this.fixedList.filter(e => !lst.includes(e))
  }

  clearFixed () {
    this.fixedList = [0, 7] // 0 and 7 are always fixed
  }

  addConstraint (A, B, elastic, minLength, maxLength) {
    if(this.constraintCount === this.maxConstraints) return false

    const offset = this.constraintCount * CONSTRAINT_SIZE

    const constraint = {
      seg: new Uint32Array(this.constraintBuffer, offset, 2),             // [start, end]
      data: new Float32Array(this.constraintBuffer, offset+8, 3) // [k, min, max]
    }

    constraint.seg[CON_A] = A
    constraint.seg[CON_B] = B
    constraint.data[CON_ELASTIC] = elastic
    constraint.data[CON_MIN] = minLength
    constraint.data[CON_MAX] = maxLength

    this.constraints.push(constraint)
    this.constraintCount += 1
    return true
  }

  syncSimTo (segs) {
    if(segs.length*2 !== this.curr.length) {
      console.warn('Mismatch:')
    }
    for(let i = 0; i !== segs.length; ++i) {
      this.setFixed(i, segs[i].point)
    }
  }

  copySim (segs) {
    if(segs.length*2 !== this.curr.length) {
      console.warn('Mismatch:')
    }
    for(let i = 0; i !== segs.length; ++i) {
      const s = segs[i]
      s.point.set(this.curr[2*i], this.curr[2*i+1])
    }
  }

  // Copy the changes from the track to the sim
  syncToTrack (track) {
    const count = track.delta.points.length
    for(let i = 0; i !== count; ++i) {
      if(track.delta.points[i]) {
        this.setFixed(i, track.delta.points[i])
      }
    }
  }

  // Copy the changes in the sim to the track.  Any values not the same must be updated in the track
  updateTrackFromSim (track) {
    const count = track.delta.points.length
    for(let i = 0; i !== count; ++i) {
      if(track.delta.points[i] != null) {
        track.delta.points[i].set(this.curr[2*i], this.curr[2*i+1])
      } else {
        track.delta.points[i] = new paper.Point(this.curr[2*i], this.curr[2*i+1])
      }
    }
    return track
  }

  update () {
    const STEPS = 2
    this.updateMotion()
    for(let i = 0; i !== STEPS; ++i) this.updateConstraints()
  }

  updateMotion () {
    const prev = i => Math.max(0, i-1)
    const next = i => Math.min(this.segs.length-1, i+1)

    for(let i = 0, iX = 0, iY = 1; i !== this.jointCount; ++i, iX += 2, iY += 2) {
      if(this.fixedList.includes(i)) {
        this.delta[iX] = 0; this.delta[iY] = 0;
        this.curr[iX] = this.prev[iX]; this.curr[iY] = this.prev[iY]
      } else {
        this.delta[iX] = this.curr[iX] - this.prev[iX]
        this.delta[iY] = this.curr[iY] - this.prev[iY]
        this.prev[iX] = this.curr[iX]; this.prev[iY] = this.curr[iY]
        this.curr[iX] += this.delta[iX]; this.curr[iY] += this.delta[iY]

        // Pull the non-fixed points to the tangents of the neighbour fixed points
        const p = prev(i), n = next(i)
        if(p !== i && i !== n) {
          const aX = this.curr[2*p] + this.curr[2*n]; const aY = this.curr[2*p+1] + this.curr[2*n+1]
          this.curr[iX] += (aX/2 - this.curr[iX]) * .8; this.curr[iY] += (aY/2 - this.curr[iY]) * 0.8 - 7
        }
      }

    }
  }

  isFixed (idx) {
    return this.fixedList.includes(idx)
  }

  updateConstraints () {
    // Enforce the elastic constraints
    for(let i = 0; i !== this.constraintCount; ++i) {
      const constraint = this.constraints[i]

      // Start with hard constraints...
      const Ax = constraint.seg[0] * 2; const Ay = Ax + 1
      const Bx = constraint.seg[1] * 2; const By = Bx + 1
      const dx = this.curr[Bx] - this.curr[Ax]; const dy = this.curr[By] - this.curr[Ay]
      const len = Math.sqrt(dx*dx + dy*dy)

      let diff = 0
      if(len < constraint.data[CON_MIN]) {
        diff = .5 * (len - constraint.data[CON_MIN]) / len * constraint.data[CON_ELASTIC] * .1
      } else {
        diff = .5 * (len - constraint.data[CON_MIN]) / len * constraint.data[CON_ELASTIC]
      }

      if(this.isFixed(constraint.seg[0]) && this.isFixed(constraint.seg[1])) {
        this.curr[Ax] = this.prev[Ax]; this.curr[Ay] = this.prev[Ay]
        this.curr[Bx] = this.prev[Bx]; this.curr[By] = this.prev[By]
        continue
      }

      if(this.isFixed(constraint.seg[0])) {
        this.curr[Bx] -= dx * diff; this.curr[By] -= dy * diff
      } else if(this.isFixed(constraint.seg[1])) {
        this.curr[Ax] += dx * diff; this.curr[Ay] += dy * diff
      } else {
        this.curr[Ax] += dx * diff; this.curr[Ay] += dy * diff
        this.curr[Bx] -= dx * diff; this.curr[By] -= dy * diff
      }
    }

    // Force left-right constraints on points (except start & end)
    // 1) Don't allow a previous element to be further right than the next
    // 2) Don't allow a next element to be further left than the previous
    for(let i = 1; i !== this.jointCount-1; ++i) {
      const ii = 2 * i, jj = 2 *(i+1)
      const prevX = this.curr[ii], nextX = this.curr[jj]
      if(this.isFixed(i) && i+1 !== this.jointCount-1) {
        if (prevX > nextX) this.curr[jj] = prevX
      } else if(prevX > nextX) {
        this.curr[ii] = nextX
      }
    }
  }
}