import { Application } from "@hotwired/stimulus"
import '@hotwired/turbo-rails'
import * as bootstrap from 'bootstrap'

const application = Application.start()

import IpaAnimationController from './controllers/ipa_animation_controller'
application.register('ipa-animation', IpaAnimationController)

import NewIpaAnimationController from './controllers/new_ipa_animation_controller'
application.register('new-ipa-animation', NewIpaAnimationController)

// file upload
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// Configure Stimulus development experience
application.debug = true
window.Stimulus   = application

var myDropdown = document.getElementById('navbarDropdown');
var dropdown = new bootstrap.Dropdown(myDropdown);
