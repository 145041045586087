import paper from 'paper'
import Controller from '../new_animations/model/controller'

export class Main {
  constructor () {
    this.controller = null
  }

  static init () {
    (new Main()).init()
    console.log('ipa_animation/main.js')
  }

  async init () {
    await domReady()
    this.buildScene()
  }

  buildScene () {
    this.canvas = document.getElementById('head')
    paper.install(window)
    paper.setup(this.canvas)

    this.root = new paper.Group()
    this.root.name = 'root'
    this.root.applyMatrix = false

    console.log('ipa_animation/main.js', false)

    this.controller = new Controller(this.root, false)
  }
}

function domReady () {
  return new Promise((resolve => {
    if(document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', resolve)
    } else {
      resolve()
    }
  }))
}

/* This is the old implementation which was commented out on 11th September 2023.
import paper from 'paper';
import Jaw from './jaw';
import Head from './head';
import Tongue from './tongue';

export class Main {
  constructor() { }

  static init() {
    (new Main()).init()
    console.log('ipa-animation/main.js')
  }

  async init() {
    await domReady();

    this.buildScene()
    this.setupPlayer()

    // document.getElementById('state').addEventListener("submit", this.makeTransform.bind(this));

    window.engine = this
  }

  buildScene() {
    this.canvas = document.getElementById('head');
    paper.setup(this.canvas);

    let headContour = new paper.Path();

    headContour.copyContent(Head.initialContour())
    headContour.copyAttributes(Head.initialContour())
    headContour.visible = true
    headContour.name = 'head'

    let jawContour = new paper.Path();
    let jawOpenContour = new paper.Path();
    let jawCloseContour = new paper.Path();

    jawCloseContour.copyContent(Jaw.closeContour())
    jawOpenContour.copyContent(Jaw.openContour())

    jawContour.copyContent(Jaw.closeContour())
    jawContour.copyAttributes(Jaw.closeContour())
    jawContour.visible = true
    jawContour.name = 'jaw'

    let tongueContour = new paper.Path();

    tongueContour.copyContent(Tongue.initialContour())
    tongueContour.copyAttributes(Tongue.initialContour())
    tongueContour.visible = true
    tongueContour.name = 'tongue'

    this.contour = new paper.Group({
      children: [
        headContour,
        jawContour,
        jawCloseContour,
        jawOpenContour,
        tongueContour
      ]
    })

    this.contour.fitBounds(paper.view.bounds);

    this.buildPositions()

    this.head = new Head(headContour)
    this.jaw = new Jaw(jawContour, jawOpenContour, jawCloseContour)
    this.tongue = new Tongue(tongueContour, this.positions)
  }

  buildPositions() {
    const alveolarBaseIndex = 945;
    const alveolarBase = this.contour.children['head'].getPointAt(alveolarBaseIndex)
    const alveolarPostBase = this.contour.children['head'].getPointAt(alveolarBaseIndex - 15)
    const palatalHardBase = this.contour.children['head'].getPointAt(alveolarBaseIndex - 30)
    const palatalSoftBase = this.contour.children['head'].getPointAt(alveolarBaseIndex - 45)
    const uvularBase = this.contour.children['head'].getPointAt(alveolarBaseIndex - 60)

    this.positions = {
      alveolar: this.position(alveolarBase),
      alveolar_post: this.position(alveolarPostBase),
      palatal_hard: this.position(palatalHardBase),
      palatal_soft: this.position(palatalSoftBase),
      uvular: this.position(uvularBase),
    }
  }

  position(basePosition) {
    return {
       x: basePosition.x,
       y: {
         cave_up_touch: basePosition.y,
         cave_up: basePosition.y + 10,
         cave_near_up: basePosition.y + 20,
         cave_up_mid: basePosition.y + 30,
         cave_mid: basePosition.y + 40,
         cave_low_mid: basePosition.y + 50,
         cave_near_low: basePosition.y + 60,
         cave_low: basePosition.y + 70,
         pharyngeal: basePosition.y + 80,
         laryngeal: basePosition.y + 90
       }
    }
  }

  // makeTransform(event) {
  //   event.preventDefault()

  //   const formData = Object.fromEntries(new FormData(event.target).entries());

  //   this.transform(JSON.parse(formData['payload']))
  // }

  transform(state) {
    this.jaw.transform(state);
    this.tongue.transform(state);
  }

  setupPlayer() {
    this.frames = window.animation;

    this.playerScene = this.canvas;

    // paper.projects[0].activate()

    document.getElementById('play').addEventListener('click', (event) => {
      // this.playerScene.activate();
      // this.playerScene.clear();

      this.contour.onFrame = (event) => {
        const numberOfFrames = this.frames.length;

        const switchPoints = [...Array(numberOfFrames + 1).keys()].map((el) => el * 60);

        if (event.count == numberOfFrames * 60) {
          this.contour.onFrame = undefined
          this.transform({ 'jaw': false, 'tongue': {} })

          return
        }

        if (switchPoints.includes(event.count)) {
          // console.log(event.count)
          this.transform(this.frames[event.count / 60])
          // contour.importJSON(this.slides[event.count / 60].contourJSON);
          // contour.fitBounds(this.playerScene.view.bounds);
        }
      }
    })
  }

}

function domReady() {
  return new Promise((resolve => {
    if (document.readyState == "loading") {
      document.addEventListener("DOMContentLoaded", resolve);
    } else {
      resolve();
    }
  }));
}

*/