import paper from "paper";
import baseContourJSON from "../../fixtures/new/head/jaw-base.json";

const headCurves = {
  jaw_base: `["Path",{"applyMatrix":true,"segments":[[[430.05435,1018.14559],[2.22139,-0.82933],[-1.47025,0.5489]],[[427.6,1018.9],[0.07965,0.07965],[-0.2,-0.2]],[[421,1005.6],[3.4,7.1],[-10.4,-21.8]],[[383.4,907],[13.8,41.6],[-7.2,-21.7]],[[368.5,851.7],[5.3,24.5],[-3.9,-18]],[[352.3,801.2],[5.7,11.7],[-4.1,-8.3]],[[333.6,782.6],[8.2,3.9],[-9.4,-4.4]],[[293.5,777.8],[26.6,-0.1],[-42,0.2]],[[212.5,769.9],[20.3,6.3],[-21.6,-6.7]],[[173.2,742],[7.9,14.2],[-9.3,-16.6]],[[167.5,694],[-5.4,15.6],[2.6,-7.6]],[[170.4,674],[0.1,10.2],[0,-0.51596]],[[170.38955,672.46701],[0.00699,0.50611],[-0.16906,-12.24571]]]}]`,
  jaw_teeth: `["Path",{"applyMatrix":true,"segments":[[[198.6132,672.07201],[0.10057,-2.5596],[-0.47679,12.135]],[[199.3,691],[-0.99098,-3.05551],[1.2,4.1]],[[200.3,678],[0.3,16],[-0.5,-21.4]],[[203,632.5],[-1.9,2.3],[0.7,-0.9]],[[204.5,626.2],[-0.1,2.6],[0.2,-5.3]],[[209.1,611],[-1.4,0],[0.5,0]],[[210,616.9],[0,-3.3],[0,6.5]],[[214.1,631.5],[-2.7,-3],[0.9,1.1]],[[216.5,636.3],[-0.3,-1.5],[0.19061,0.74882]],[[217.02936,637.98711],[-0.16297,-0.37112],[1.03402,2.35468]],[[227.869530,650.304434],[0.0,0.0],[0.0,0.0]]]}]`,
  cranium: `["Path",{"applyMatrix":true,"segments":[[162.31795,572.01169],[[162.8,563.9],[0,0],[0.5,-15.3]],[[157.7,539.4],[4.8,5.4],[-4,-4.6]],[[136.5,529.1],[13.2,3.8],[-22.6,-6.5]],[[93.3,506.7],[9.2,10],[-6.6,-7.3]],[[85.2,483.6],[-0.6,9.8],[1,-15.2]],[[122.6,424.8],[-26.3,27.7],[31.7,-33.5]],[[173.1,352],[-4.9,19.3],[0.6,-2.5]],[[175,315],[-0.4,18.2],[0.8,-35.9]],[[183,248.5],[-6.5,25],[6.9,-26.6]],[[217.3,152],[-11.7,25.8],[14.5,-32]],[[273,77.2],[-22.6,17.8],[44.1,-34.6]],[[491.5,9],[-95.4,8.9],[17.8,-1.6]],[[552,7.5],[-33.5,-0.3],[34.7,0.3]],[[612.5,10.4],[-18.4,-2.20004],[99.6,12.2]],[[830.6,103.8],[-48.9,-51.4],[13.3,14.1]],[[866.8,153],[-6.8,-13.4],[2.4,4.7]],[[875.5,169.5],[-2.4,-4.4],[2.3,4.4]],[[887.8,199.5],[-4.4,-12.1],[20.5,55.6]],[[921,398.8],[0,-67.2],[0,24.8]],[[917.4,444.9],[3.5,-20],[-3.1,18.2]],[[907.8,481.3],[5.3,-14],[-11.6,30.1]],[[844,565.5],[35.6,-32.1],[-4.2,3.8]],[[798,603.9],[27.4,-22.5],[-19,15.6]],[[756.3,649.8],[9,-15.2],[-13.4,22.5]],[[712.8,761],[6.2,-27.5],[-2,9]],[[710.5,790],[0,-15.9],[0.1,16.9]],[[713.8,824],[-2.9,-14.1],[8.1,38.7]],[[755.4,938.4],[-24.1,-49.9],[4.8,9.8]],[[763.8,956.6],[0.2,-0.2],[-0.2,0.2]],[[737.8,961.4],[14.1,-2.4],[-30.6,5.4]],[[673.5,975],[25,-6.3],[-10.4,2.7]],[[641,981.9],[7.4,-1.1],[-7.4,1.1]],[[575.7,992.5],[28.5,-4.7],[-28.5,4.7]],[[521.7,1001],[1.2,0],[-1.8,0]],[[515.6,991.2],[3.2,8.2],[-2.2,-5.3]],[[504.4,965],[4,9.1],[-10.3,-23.6]],[[480.5,903],[9.8,28.8],[-7.63557,-22.41726]],[[461.91767,842.02149],[4.94026,18.923],[-0.10638,-0.40749]]]}]`,
  upper_teeth: `["Path",{"applyMatrix":true,"segments":[[[290.71464,549.30593],[4.71505,0.31247],[-16.54992,-1.09679]],[[242,553.5],[12.29669,-3.7357],[-15.6,4.7]],[[217.6,575.6],[4.4,-13.4],[-1.3,4]],[[208.4,588.1],[5.9,-5.7],[-5.8,5.8]],[[201.5,597.5],[-0.5,-1.6],[1.3,4.1]],[[204.4,611.7],[-0.6,-5.1],[0.8,6.7]],[[199.1,610.4],[4.6,7.7],[-5.4,-9]],[[190.9,579.6],[0.1,11.7],[-0.08114,-4.54389]],[[190.42181,572.04425],[0.18423,0.0481],[-0.04282,-0.01118]]]}]`,
  inner_nasal: `["Path",{"applyMatrix":true,"segments":[[[453.29469,795.80525],[-0.91169,11.98433],[0.28938,-3.80395]],[[453.4,783.1],[0.21684,4.67414],[-1.6,-35.9]],[[442.5,639],[2.4,16.8],[-0.9,-6.3]],[[438,586.6],[1.1,16.3],[-0.6,-8.7]],[[436,505.6],[0.5,35.8],[-1.5,-102.3]],[[416.6,373.2],[15.6,14.3],[-13.4,-12.4]],[[336,364.9],[48.7,-6],[-58.5,7.2]],[[227.5,393],[33.4,-16.6],[-18.2,9.1]],[[185.2,420.5],[12.7,-11],[-25.7,22.4]],[[142,460.2],[5,-5.8],[-11.6,13.8]],[[133.7,489.8],[-5.7,-7.1],[4.2,5.2]],[[164.1,507.1],[-21.1,-9.2],[29.2,12.8]],[[217.7,522],[-20.9,-1.1],[9.8,0.5]],[[266.7,523],[-17.2,0],[7.61634,0]],[[285.94242,523.13031],[-5.34677,-0.09834],[17.81967,0.32776]]]}]`,
  throat_front: `["Path",{"applyMatrix":true,"segments":[[[419.62985,860.88095],[-2.48117,-13.1267],[0.72927,3.85821]],[[422.7,874.5],[-1.3175,-5.27],[6.7,27.2]],[[474.2,988.8],[-19.9,-32],[3.9,6.2]],[[480.9,1000.4],[0.2,-0.2],[-0.2,0.1]],[[473.5,1002.5],[3.9,-1],[-11.7,3]],[[439.7,1014.2],[12.4,-5.3],[-6.5,2.8]],[[427.6,1018.9],[0.2,0.2],[-0.06228,-0.06228]],[[426.67103,1017.16771],[0.53287,1.04527],[-1.17828,-2.3113]]]}]`,
}

export function drawHead (root) {
  const group = new paper.Group()
  group.name = 'HeadGroup'

  const colour = '#000000FF'

  const jaw_teeth = new paper.Path({ visible: true })
  jaw_teeth.strokeColor = colour
  jaw_teeth.importJSON(headCurves.jaw_teeth)
  group.addChild(jaw_teeth)

  const cranium = new paper.Path({ visible: true })
  cranium.strokeColor = colour
  cranium.importJSON(headCurves.cranium)
  group.addChild(cranium)

  const upper_teeth = new paper.Path({ visible: true })
  upper_teeth.strokeColor = colour
  upper_teeth.importJSON(headCurves.upper_teeth)
  group.addChild(upper_teeth)

  const inner_nasal = new paper.Path({ visible: true })
  inner_nasal.strokeColor = colour
  inner_nasal.importJSON(headCurves.inner_nasal)
  group.addChild(inner_nasal)

  const throat_front = new paper.Path({ visible: true })
  throat_front.strokeColor = colour
  throat_front.importJSON(headCurves.throat_front)
  group.addChild(throat_front)

  group.visible = true
  root.addChild(group)

  return {
    jaw_teeth,
    cranium,
    upper_teeth,
    inner_nasal,
    throat_front,
  }
}