// Definitions for the locations on the tongue, articulators, split points etc.
// Standardised all vars to use snake case versus camel case

import paper from 'paper'

// Some important visual definitions

export const ALPHA_COLOURS = true // Determines whether to use alpha transparency on the curves or not
export const ZOOMED_IN = true // Determine whether the model is zoomed in on the mouth or not
export const HIDE_PATHS = false
export const BUILD_GUIDELINES = true
export const HIGHLIGHT_SPLIT_POINT = false

export const SHOW_CONTROLS = true;

// Split points on tongue (spline knots) - these were the original points on the tongue in the original code
export const CONTROL_POINTS = [
  225.33292, 584.97453,
  246.65277, 567.81541,
  263.55057, 561.66866,
  295.21185, 558.78643,
  343.34345, 583.88164,
  375.38567, 619.37052,
]

/*
This block is the stored state for the tongue curve at it's most basic.  These points
can be updated and moved if you want to change the starting positions.  This state is
also defined as the tongue at rest.  It is the state that the tongue will revert to by
default.  All ratios between points, distances, and tangents are based on this original
model.

The points are arranged from the base front start of the tongue to the back of the
throat.  They were derived from the original curve and simplified to reduce the number
of points.  As they are cubic Bezier curves, the definition below should be general enough.
*/
export const DEFAULT_TONGUE_STATE = [
  [227.869530,650.304434,10.379433,10.105583,-10.379433,-10.105583],
  [226.204667,585.622831,-9.427530,16.898988,6.784429,-13.818407],
  [246.600000,567.700000,-2.233343,1.467844,2.233343,-1.467844],
  [263.427053,561.158768,-2.910937,0.487135,2.910937,-0.487135],
  [295.115613,559.581079,-12.637381,-3.135353,13.813710,1.984213],
  [344.059736,583.130258,-4.762250,-3.627433,4.762250,3.627433],
  [374.486446,620.038290,-3.308129,-10.255137,3.308129,10.255137],
  [399.195221,754.049209,9.423961,-1.107707,-9.423961,1.107707]
]

// [ [point0, handleIn0, handleOut0], [point1, ...], ... ]
export const DEFAULT_TONGUE_SEGS = DEFAULT_TONGUE_STATE.map(e => [e.slice(0, 2), e.slice(2, 4), e.slice(4, 6)])
export const DEFAULT_TONGUE_CURVE = DEFAULT_TONGUE_SEGS.map(
  e => new paper.Segment(
    new paper.Point(e[0]),
    new paper.Point(e[1]),
    new paper.Point(e[2])
  )
)

// Shorthand articulator names to the control point index
export const SHORT_ARTICULATORS = {
  undertip: 1,
  tip: 2,
  blade: 3,
  middle: 4,
  back: 5,
  root: 6,
}

/* Tongue Split points (defining the position of the articulators) */
export const ARTICULATORS = {
  lip_down: 1,
  teeth_down: 2,
  tongue_tip: 3,
  tongue_blade: 4,
  tongue_front_or_middle: 5,
  tongue_back: 6,
  tongue_root: 7,
  tongue_undertip: 8,
  epiglottal: 9,
  glottal: 10
}

// Encodes the position of the point in world and model space as the tongue is stored in world space directly
export const ARTICULATOR_POSITIONS_X = {
  out: [0, 146.870001],
  lip: [1, 166.592501],
  teeth: [2, 204.322501],
  alveolar: [3, 213.755001],
  alveolar_post: [4, 228.332501],
  palatal_hard: [5, 284.070001],
  palatal_soft: [6, 341.663],
  uvular: [7, 406],
}

// This START and END are the regions before the pharyngeal separated into 7 regions, also model/world space
export const START = 549.229998
export const END = 645.269998
export const ratio = (END - START) / 7
export const yOffset = (idx) => START + idx * ratio

// The Y-position of the key
export const ARTICULATOR_POSITIONS_Y = {
  cave_up_touch: [0, yOffset(0)],
  cave_up: [1, yOffset(1)],
  cave_near_up: [2, yOffset(2)],
  cave_up_mid: [3, yOffset(3)],
  cave_mid: [4, yOffset(4)],
  cave_low_mid: [5, yOffset(5)],
  cave_near_low: [6, yOffset(6)],
  cave_low: [7, yOffset(7)],
  pharyngeal: [8, 733.592497],
  laryngeal: [9, 817.627497],
}

// These are the specific Y offsets for the articulators.  Each articulator has a set of positions they can occupy.  For
// example, the out, lip, and teeth position cannot have all positions in the Y positions.  The program will currently

const ALVEOLAR_LIMITS = [583, 632]
const ALVEOLAR_POST_LIMITS = [559, 629]
const PALATAL_HARD_LIMITS = [549, 607]
const PALATAL_SOFT_LIMITS = [564, 592]
const UVULAR_LIMITS = [643, 723]

const calcOffset = (idx, tbl) => tbl[0] + idx * ((tbl[1] - tbl[0])/7)

// Notes:
// 1) The positions were extracted from a diagram with indexing starting at 0
// 2) The contiguous positions are generated during calcOffset, the non-contiguous positions are fixed

export const ARTICULATOR_LIMITS_Y = {
  out: {
    cave_up_mid: [3, 625],
  },
  lip: {
    cave_up_touch: [0, 615],
    cave_near_low: [6, 640],
  },
  teeth: {
    cave_up_touch: [0, 606],
    cave_near_low: [6, 640],
  },
  alveolar: {
    cave_up_touch: [0, calcOffset(0, ALVEOLAR_LIMITS)],
    cave_up: [1, calcOffset(1, ALVEOLAR_LIMITS)],
    cave_near_up: [2, calcOffset(2, ALVEOLAR_LIMITS)],
    cave_up_mid: [3, calcOffset(3, ALVEOLAR_LIMITS)],
    cave_mid: [4, calcOffset(4, ALVEOLAR_LIMITS)],
    cave_low_mid: [5, calcOffset(5, ALVEOLAR_LIMITS)],
    cave_near_low: [6, calcOffset(6, ALVEOLAR_LIMITS)],
    cave_low: [7, calcOffset(7, ALVEOLAR_LIMITS)],
  },
  alveolar_post: {
    cave_up_touch: [0, calcOffset(0, ALVEOLAR_POST_LIMITS)],
    cave_up: [1, calcOffset(1, ALVEOLAR_POST_LIMITS)],
    cave_near_up: [2, calcOffset(2, ALVEOLAR_POST_LIMITS)],
    cave_up_mid: [3, calcOffset(3, ALVEOLAR_POST_LIMITS)],
    cave_mid: [4, calcOffset(4, ALVEOLAR_POST_LIMITS)],
    cave_low_mid: [5, calcOffset(5, ALVEOLAR_POST_LIMITS)],
    cave_near_low: [6, calcOffset(6, ALVEOLAR_POST_LIMITS)],
    cave_low: [7, calcOffset(7, ALVEOLAR_POST_LIMITS)],
  },
  palatal_hard: {
    cave_up_touch: [0, calcOffset(0, PALATAL_HARD_LIMITS)],
    cave_up: [1, calcOffset(1, PALATAL_HARD_LIMITS)],
    cave_near_up: [2, calcOffset(2, PALATAL_HARD_LIMITS)],
    cave_up_mid: [3, calcOffset(3, PALATAL_HARD_LIMITS)],
    cave_mid: [4, calcOffset(4, PALATAL_HARD_LIMITS)],
    cave_low_mid: [5, calcOffset(5, PALATAL_HARD_LIMITS)],
    cave_near_low: [6, calcOffset(6, PALATAL_HARD_LIMITS)],
    cave_low: [7, calcOffset(7, PALATAL_HARD_LIMITS)],
  },
  palatal_soft: {
    cave_up_touch: [0, calcOffset(0, PALATAL_SOFT_LIMITS)],
    cave_up: [1, calcOffset(1, PALATAL_SOFT_LIMITS)],
    cave_near_up: [2, calcOffset(2, PALATAL_SOFT_LIMITS)],
    cave_up_mid: [3, calcOffset(3, PALATAL_SOFT_LIMITS)],
    cave_mid: [4, calcOffset(4, PALATAL_SOFT_LIMITS)],
    cave_low_mid: [5, calcOffset(5, PALATAL_SOFT_LIMITS)],
    cave_near_low: [6, calcOffset(6, PALATAL_SOFT_LIMITS)],
    cave_low: [7, calcOffset(7, PALATAL_SOFT_LIMITS)],
  },
  uvular: {
    cave_up_touch: [0, calcOffset(0, UVULAR_LIMITS)],
    cave_up: [1, calcOffset(1, UVULAR_LIMITS)],
    cave_near_up: [2, calcOffset(2, UVULAR_LIMITS)],
    cave_up_mid: [3, calcOffset(3, UVULAR_LIMITS)],
    cave_mid: [4, calcOffset(4, UVULAR_LIMITS)],
    cave_low_mid: [5, calcOffset(5, UVULAR_LIMITS)],
    cave_near_low: [6, calcOffset(6, UVULAR_LIMITS)],
    cave_low: [7, calcOffset(7, UVULAR_LIMITS)],
    pharyngeal: [8, 723],
    laryngeal: [9, 824],
  }
}

// The split points are the control points named by (short name) articulator
export const SPLIT_POINTS = {
  undertip: [225.33292, 584.97453],
  tip: [246.6, 567.7],
  blade:[263.56549, 561.72044],
  front:[295.21185, 558.78643],
  back:[343.4181, 583.79632],
  root:[375.32166, 619.41573]
}

// The transformation of the jaw when changing state from closed to open
export const JAW_TRANSFORM = [6.826, 32.424]