// Animate a sine wave in a path...

import paper from 'paper'
import {lerpPoints, perp} from "./maths-utils";

const TWO_PI = 2.0 * Math.PI

export function createCup (start, end, dir, resolution) {
  const spline = new paper.Path()
  spline.strokeColor = '#000000'
  if(end.x < start.x) { const t = start; start = end; end = t }
  const axis = end.subtract(start)
  const normal = perp(axis)

  const mid = (start.x + end.x) * .5

  const dist = end.x - start.x
  const inv = 1./(resolution-1)

  const segs = []
  for(let i = 0; i !== resolution; ++i) {
    const P = new paper.Point(lerpPoints(start, end, i*inv))
    const x = (P.x - mid)/dist;
    const f = 2. * x * x
    segs.push(P.add(normal.multiply(-dir*f)).subtract(normal.multiply(-dir*.5)))
  }

  spline.segments = segs
  return spline
}

export function computeSine (x, t, freq, start, end) {
  const dist = end - start
  const factor = Math.max(Math.min(Math.abs(x - start)/dist, Math.abs(end - x)/dist), .0001);
  return Math.sin(TWO_PI * freq * ((x/dist + t))) * 10 * factor;
}

export function createSineWave (start, end, resolution, freq) {
  if(end.x < start.x) { const t = start; start = end; end = t }
  const spline = new paper.Path()
  const count = resolution + 2 // We need space for the start and end points which do *not* move

  // Get axis so we can use normal value for wave direction
  const axis = (end.subtract(start)).normalize()
  const normal = perp(axis).normalize()

  const segs = []
  for(let i = 0; i !== count; ++i) {
    const P = new paper.Point(lerpPoints(start, end, i / count))
    const s = computeSine(P.x, 0., freq, start.x, end.x)
    const j = normal.multiply(s)
    segs.push(P.add(j))
  }

  spline.segments = segs
  spline.strokeColor = '#000000'
  spline.freq = freq
  spline.count = count
  spline.time = 0
  spline.start = start
  spline.end = end
  spline.tangent = axis
  spline.normal = normal

  return spline
}

export function updateSpline (waveSpline, delta) {
  let t = waveSpline.time
  t += delta
  waveSpline.time = t

  const { start, end, freq, count, normal } = waveSpline

  const segs = waveSpline.segments
  for(let i = 0; i !== segs.length; ++i) {
    const P = new paper.Point(lerpPoints(start, end, i / count))
    segs[i].point = P.add(normal.multiply(computeSine(P.x, t, freq, start.x, end.x)))
  }
}

// Helper function to compute the sine wave value
function computeSine2(x, amplitude, freq, minX, maxX) {
  const t = (x - minX) / (maxX - minX);
  return amplitude * Math.sin(2 * Math.PI * freq * t);
}

// This applies the sine wave to an input curve (i.e. glottis or aspiration curve)
export function applySineWave(inputPath, amplitude, freq, resolution) {
  const pathCopy = inputPath.clone()

  // Divide the input path into the specified resolution
  const pathLength = pathCopy.length
  const sampleInterval = pathLength / resolution
  const sinePathPoints = []

  for (let i = 0; i <= resolution; ++i) {
    const offset = i * sampleInterval
    const point = pathCopy.getPointAt(offset)
    const normal = pathCopy.getNormalAt(offset)

    const sineValue = computeSine2(offset, amplitude, freq, 0, pathLength)
    const sinePoint = point.add(normal.multiply(sineValue))
    sinePathPoints.push(sinePoint)
  }

  const sinePath = new paper.Path()
  sinePath.addSegments(sinePathPoints)
  sinePath.strokeColor = '#000000'
  sinePath.time = 0
  sinePath.freq = freq
  sinePath.amplitude = amplitude
  sinePath.resolution = resolution

  return sinePath
}

export function updateWaveSpline(waveSpline, inputPath, delta) {
  let t = waveSpline.time
  t += delta
  waveSpline.time = t

  const { freq, resolution } = waveSpline

  // Divide the input path into the specified resolution
  const pathLength = inputPath.length
  const sampleInterval = pathLength / resolution

  for (let i = 0; i <= resolution; ++i) {
    const offset = i * sampleInterval
    const point = inputPath.getPointAt(offset)
    const normal = inputPath.getNormalAt(offset)
    if(!point) continue
    const sineValue = computeSine(offset, t, freq, 0, pathLength)
    const sinePoint = point.add(normal.multiply(sineValue))
    waveSpline.segments[i].point = sinePoint
  }
}

// These sine waves are for the airstream animations to animate airflow
export function createScaledSineWave(inputPath, amplitude, freq, resolution) {
  const pathCopy = inputPath.clone()
  const pathLength = pathCopy.length
  const sampleInterval = pathLength / resolution
  const sinePathPoints = []

  for (let i = 0; i <= resolution; ++i) {
    const offset = i * sampleInterval
    const point = pathCopy.getPointAt(offset)
    const normal = pathCopy.getNormalAt(offset)

    // Compute the scaled amplitude
    const scaledAmplitude = amplitude * (offset / pathLength)
    const sineValue = computeSine2(offset, scaledAmplitude, freq, 0, pathLength)
    const sinePoint = point.add(normal.multiply(sineValue))
    sinePathPoints.push(sinePoint)
  }

  const sinePath = new paper.Path()
  sinePath.addSegments(sinePathPoints)
  sinePath.strokeColor = '#000000'
  sinePath.freq = freq
  sinePath.amplitude = amplitude
  sinePath.resolution = resolution
  sinePath.time = 0

  return sinePath
}


export function computeSine3 (x, t, amplitude, freq, start, end) {
  const dist = end - start
  return Math.sin(TWO_PI * freq * ((x/dist + t))) * amplitude;
}

export function updateScaledSpline(waveSpline, inputPath, delta) {
  let t = waveSpline.time
  t += delta
  waveSpline.time = t

  const { freq, resolution, amplitude } = waveSpline
  const pathLength = inputPath.length
  const sampleInterval = pathLength / resolution

  for (let i = 0; i <= resolution; ++i) {
    const offset = i * sampleInterval
    const point = inputPath.getPointAt(offset)
    const normal = inputPath.getNormalAt(offset)

    // Compute the scaled amplitude
    const scaledAmplitude = amplitude - amplitude * (offset / pathLength)

    const sineValue = computeSine3(offset, t, scaledAmplitude, freq, 0, pathLength)
    const sinePoint = point.add(normal.multiply(sineValue))
    waveSpline.segments[i].point = sinePoint
  }
}
