import paper from 'paper'
import { SplineInput } from "../splineInput"
import {lerp, lerpSegments} from "../../maths/maths-utils";

// Points ordered from closest to tongue to closest to glottis
const epiglottisDefaultState = `["Path",{"applyMatrix":true,"segments":[[[399.1953,754.00511],[0.02467,-0.94291],[-0.0011,0.04188]],[[399.2,754.1],[-0.00425,-0.02126],[0.7,1.8]],[[413.9,731],[-3.1,7.8],[3.8,-9.8]],[[420.3,721.6],[-2.2,-0.9],[5.5,2.1]],[[405,772.5],[13.3,-23.8],[-6,10.9]],[[403,804],[-4.4,-15.3],[2.68832,9.52701]],[[410.2196,823.8739],[-2.31009,-4.023],[2.58797,4.50691]]]}]`
const epiglottisHalfClosedState = `["Path",{"applyMatrix":true,"segments":[[[411.68902,756.24296],[91.98893,-21.78505],[-0.00033,7.17996]],[[418.85782,757.46599],[-2.3904,3.57732],[2.59913,-3.0437]],[[430.43522,737.39577],[-4.5137,9.50739],[5.32098,-12.49653]],[[440.71717,726.21381],[-0.66,-5.04826],[4.03913,6.00555]],[[425.37608,770.15338],[6.97641,-13.71011],[-4.18913,9.24283]],[[415.21956,798.71643],[1.06913,-9.36826],[-2.7772,9.42821]],[[410.21805,822.59708],[-4.87401,-9.56885],[3.16552,5.53305]]]}]`
const epiglottisClosedState = `["Path",{"applyMatrix":true,"segments":[[[417.04347,757.20204],[131.40219,-30.7174],[0,10.23913]],[[427.2826,758.90856],[-3.41304,5.11957],[3.41304,-5.11957]],[[437.52174,740.13682],[-5.11957,10.23913],[5.97283,-13.65218]],[[449.46739,728.19116],[0,-6.82609],[3.41304,7.67935]],[[434.10869,769.14769],[4.2663,-9.38587],[-3.41304,8.53261]],[[420.45652,796.45204],[3.41304,-6.82609],[-5.11957,9.38587]],[[410.21739,822.04987],[-5.97283,-11.94565],[3.41304,5.97283]]]}]`

export default class Epiglottis {
  constructor (root, { colour, width }) {
    this.root = root

    this.animationDuration = .5
    this.raf = 0.

    const A = new paper.Path({ visible: false })
    A.importJSON(epiglottisDefaultState)
    this.startState = A.segments

    const B = new paper.Path({ visible: false })
    B.importJSON(epiglottisClosedState)
    this.endState = B.segments

    const C = new paper.Path({ visible: false })
    C.importJSON(epiglottisHalfClosedState)
    this.halfState = C.segments

    this.epiglottis = new paper.Path()
    this.epiglottis.importJSON(epiglottisDefaultState)
    this.epiglottis.strokeColor = colour ?? 'black'
    this.epiglottis.strokeWidth = width ?? 1

    this.root.addChild(this.epiglottis)

    this.epiglottis_ = false
    this.epiHalf_ = false
  }

  get aniDuration () {
    return this.animationDuration
  }

  set aniDuration (value) {
    this.animationDuration = value
  }

  interpolateSpline (source, target, result, duration) {
    const start = performance.now()
    const scale = 1./duration

    if(this.raf > 0) cancelAnimationFrame(this.raf)

    const draw = () => {
      const t = (performance.now() - start) * 1e-3
      lerpSegments(source, target, t * scale, result)
      if(t < duration) this.raf = requestAnimationFrame(draw)
      else {
        lerpSegments(source, target, 1.0, result)
        this.raf = 0.
      }
    }

    draw()
  }

  get epiglottisPoint () {
    return this.epiglottis.segments[0].point.clone()
  }

  get extended () { return this.epiglottis_ }
  set extended (val) {
    if(this.epiglottis_ === val) return

    const source = this.epiglottis_ ? this.endState : (this.epiHalf_ ? this.halfState : this.startState)
    const target = this.epiglottis_ ? (this.epiHalf_ ? this.halfState : this.startState) : this.endState

    this.epiglottis_ = val

    this.interpolateSpline(source, target, this.epiglottis.segments, this.animationDuration)
  }

  get halfClosed () { return this.epiHalf_ }
  set halfClosed (val) {
    if(this.epiHalf_ === val) return

    const source = this.epiHalf_ ? (this.epiglottis_ ? this.endState : this.halfState) : this.startState
    const target = this.epiHalf_ ? this.startState : (this.epiglottis_ ? this.endState : this.halfState)

    this.epiHalf_ = val
    if(this.epiglottis_) return

    this.interpolateSpline(source, target, this.epiglottis.segments, this.animationDuration)
  }
}