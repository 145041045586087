import paper from 'paper'
import Controller from './model/controller'

export class Main {
  constructor () {
    this.controller = null
  }

  static init () {
    (new Main()).init()
  }

  async init () {
    await domReady()
    this.buildScene()
  }

  buildScene () {
    this.canvas = document.getElementById('head')
    paper.install(window)
    paper.setup(this.canvas)

    this.root = new paper.Group()
    this.root.name = 'root'
    this.root.applyMatrix = false

    this.controller = new Controller(this.root, true)
  }
}

function domReady () {
  return new Promise((resolve => {
    if(document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', resolve)
    } else {
      resolve()
    }
  }))
}
