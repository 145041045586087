// Methods to return geometry for displaying models

import { Path } from 'paper'
import baseContourJSON from '../fixtures/new/base-contour.json'
import closedJawJSON from '../fixtures/new/closed-jaw.json'
import headJSON from '../fixtures/new/head.json'
import openJawJSON from '../fixtures/new/open-jaw.json'
import tongueJSON from '../fixtures/new/tongue.json'

const MODEL_TABLE = {
  full: baseContourJSON,
  closedJaw: closedJawJSON,
  openJaw: openJawJSON,
  head: headJSON,
  tongue: tongueJSON
}

export function createModel (name) {
  if (name in MODEL_TABLE) {
    const curve = new Path({ visible: false })
    curve.importJSON(MODEL_TABLE[name])
    return curve
  }
  return null
}

export function loadModels () {
  return {
    head: createModel('head'),
    closedJaw: createModel('closedJaw'),
    openJaw: createModel('openJaw'),
    tongue: createModel('tongue')
  }
}