/*
This is an assortment of routines for breaking down the current contours to extract the individual paths
required for animation, e.g. tongue, glottis, vocal chords, etc.

Split points on Head Contour in Local Space

Voice:
	TOP: [415.963, 829.485] - [448.166, 814.153]
	BOTTOM: [420.616, 839.835] - [451.121, 825.445]
Glottis: same as voice
Lips:
	TOP: [162.300, 577.500] - [190.452, 572.078]
	BOTTOM: [170.390, 672.467] - [198.613, 672.072]
Velum:
	[378.210, 593.088] - [394.465, 564.754]
Mouth: (Lips Closed/Open)
    Same as bottom Lip
Jaw:
	Change to rotation

18th Feb:
  - split off base of tongue & glottis on both sides
  - extend velum curve
*/

import baseContourJSON from '../fixtures/new/base-contour.json'
import paper from 'paper'

// The table of model space points for splitting the head into animatable objects.
export const ANIMATION_TABLE = {
  voiceOrGlottis: {
    top: [[415.963, 829.485], [448.166, 814.153]],
    bottom: [[451.121, 825.445], [420.616, 839.835]],
  },
  lips: {
    top: [[162.300, 577.500], [190.452, 572.078]],
    bottom: [[198.613, 672.072], [170.390, 672.467]]
  },
  velum: [[378.210, 593.088], [394.465, 564.754]],
  newGlottis: {
    old_left: [[392.859, 931.881], [380.060, 898.600]],
    left: [[425.347, 883.809], [437.126, 915.217]],
    right: [[468.857, 866.760], [478.927, 898.354]]
  }
}

// Returns a table of paths extracted from the base_contour.json
export function extractAnimationPaths () {
  const contour = new paper.Path({ visible: false })
  contour.importJSON(baseContourJSON)
  // console.log('contour:', contour.segments.length)
}

export default class Extractor {
  constructor (root) {
    this.splitPoint = new paper.Path.Circle({
      center: [0, 0],
      radius: 5,
      strokeColor: 'red'
    })

    this.group = new paper.Group()
    this.group.addChild(this.splitPoint)

    const visible = false
    if(!visible) {
      this.splitPoint.visible = false
    } else {
      root.addChild(this.group)
    }

    this.headContour = new paper.Path({ visible: false })
    this.headContour.importJSON(baseContourJSON)
    this.group.addChild(this.headContour)

    this.extractCurve()
  }

  movePoint (globalPoint) {
    if(!this.splitPoint.visible) return
    this.splitPoint.position.set(globalPoint)
  }

  extractCurve () {
    if(!this.splitPoint.visible) return

    // Extract the glottis curves
    const H = this.headContour.clone()
    H.visible = false
    H.strokeColor = 'orange'

    const A = H.getNearestLocation([429, 1018])
    const B = H.getNearestLocation([170, 671])
    // const C = [198, 672]

    // Cranium Path
    //const A = H.getNearestLocation([427, 1017])
    //const B = H.getNearestLocation([419, 861])

    // New Velum
    // top = [290, 549], bottom = [285, 523]

    //const A = H.getNearestLocation([410, 824])
    //const B = H.getNearestLocation([399, 754])

    /*
    const A = H.getNearestLocation(new paper.Point(ANIMATION_TABLE.lips.bottom[0]))
    const B = H.getNearestLocation(new paper.Point(ANIMATION_TABLE.lips.bottom[1]))
    */

    const path1 = H.splitAt(A) // Returns H
    const path2 = path1.splitAt(B)
    if(path2) {
      path2.strokeColor = 'red'
      path2.visible = true
    }
    if(path1) {
      path1.strokeColor = 'blue'
      path1.visible = true
    }

    // Keep reducing the path until we have all the articulated structures removed...
    // JAW BASE START = [430, 1018]
    // JAW BASE END = [217, 638]

    if(path1) console.log('path1 segments:', path1.exportJSON())
    if(path2) console.log('path2 segments:', path2.exportJSON())
    if(H) console.log('H segments:', H.exportJSON())
  }

  updateSplitPoint (pos) {
    this.splitPoint.position = this.headContour.getNearestPoint(pos)
  }

  displaySplitPoint () {
    console.log(`[${this.splitPoint.position.x.toFixed(3)}, ${this.splitPoint.position.y.toFixed(3)}]`)
  }
}
