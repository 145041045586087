/*
Provides a method to shape a spline based on user input
*/

import paper from 'paper'
import { distSq, neighbourhood, nextRing } from '../maths/maths-utils'
import { TangentControl } from '../ui/tangentControl'

export function smooth (segs, tangentScale=.5) {
  let [prev, curr, next] = neighbourhood(segs, 0)
  for(let i = 0; i !== segs.length+1; ++i) {
    const inH = curr.point.subtract(prev.point)
    const outH = next.point.subtract(curr.point)
    const avg = inH.add(outH).multiply(.5)
    curr.handleIn.set(avg.multiply(-tangentScale))
    curr.handleOut.set(avg.multiply(tangentScale))
    prev = curr
    curr = next
    next = nextRing(segs, i+1)
  }
}

export class SplineInput {
  constructor (root, curve, controlPoints, radius) {
    this.root = root
    this.group = new paper.Group()
    this.pickPoint = -1

    this.root.addChild(this.group)

    this.foo = new paper.Path.Circle({
      center: [0, 0],
      radius: 5,
      strokeColor: 'transparent'
    })

    this.inputGroup = new paper.Group()
    this.index = controlPoints
    this.curve = curve

    // Index of control points in curve.segments
    for(let i = 0; i !== controlPoints.length; ++i) {
      const P = this.curve.segments[controlPoints[i]].point.clone()
      const circ = new paper.Path.Circle({
        center: P,
        radius: radius ?? 4,
        strokeColor: 'black'
      })
      this.inputGroup.addChild(circ)
    }

    this.root.addChild(this.inputGroup)
    this.group.addChild(this.curve)
    this.group.addChild(this.foo)

    this.tangentControl = new TangentControl(root, this.curve)
  }

  get showControls () {
    return this.inputGroup.visible
  }

  set showControls (value) {
    this.tangentControl.showControls = value
    this.inputGroup.visible = value
  }

  updateControls () {
    for(let i = 0; i !== this.index.length; ++i) {
      const P = this.inputGroup.children[i]
      P.position.set(this.curve.segments[this.index[i]].point)
    }
    this.tangentControl.updateControls()
  }

  // The picking interface allows one to select points
  pick (point) {
    if(this.tangentControl.pick(point)) return -1

    const POINTER_DIST = 7 * 7 // A little bit bigger than the radius
    for(let i = 0; i !== this.inputGroup.children.length; ++i) {
      const p = this.inputGroup.children[i].position
      const d = distSq(p, point)
      if(d < POINTER_DIST) {
        // console.log('dist:', d, this.index[i])
        this.pickPoint = this.index[i]
        this.indexPoint = i
        this.tangentControl.setSegment(this.index[i])
      }
    }

    return this.pickPoint
  }

  release (point) {
    this.pickPoint = -1
    this.indexPoint = -1
    this.tangentControl.release(point)
  }

  move (point) {
    if(this.tangentControl.move(point)) return

    if(this.pickPoint !== -1) {
      const segs = this.curve.segments
      const P = this.inputGroup.children[this.indexPoint]
      const curr = segs[this.pickPoint]
      curr.point.set(point)
      P.position.set(point)

      // If we move the point, smooth it because we want to keep the tangents relative
      this.tangentControl.setSegment(this.pickPoint)
    }
  }
}