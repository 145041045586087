import { drawHead } from "./head-curves"
/*
The head is now split into multiple disjoint segments because we need to erase the underlying
curve in order to animate the articulated part without displaying the original curve.  Also
investigating attaching the curves to the static head model so we can
 */

export default class Head {
  constructor (root, jawGroup, { colour, width }) {
    this.root = root
    this.curves = drawHead(root)

    this.jawGroup = jawGroup
    this.jawGroup.addChild(this.curves.jaw_teeth)
  }
}