/*
Controls the tangents on a spline
*/

import {intersects, neighbourhood} from '../maths/maths-utils'
import paper from 'paper'

const PICK_DIST_SQ = 7 * 7

/*
Tangents will use a logarithmic scale because it's difficult to set them when
they're so close to the points.
*/

export class TangentControl {
  constructor (root, curve, tangentScale=2.0) {
    this.root = root
    this.curve = curve
    this.segments = curve.segments
    this.tangentScale = tangentScale
    this.invTangentScale = 1./tangentScale

    this.clearSegment()

    this.group = new paper.Group()
    this.controlPoint = new paper.Point(0, 0)

    // Add 2 arms (inHandle, outHandle) and 2 control points
    this.inP = new paper.Path.Circle({
      center: [0, 0],
      radius: 5,
      strokeColor: 'blue'
    })
    this.outP = new paper.Path.Circle({
      center: [0, 0],
      radius: 5,
      strokeColor: 'blue'
    })

    this.inH = new paper.Path.Line(this.inP.position, this.controlPoint)
    this.inH.strokeColor = 'black'
    this.outH = new paper.Path.Line(this.controlPoint, this.outP.position)
    this.outH.strokeColor = 'black'
    this.group.addChild(this.inP)
    this.group.addChild(this.outP)
    this.group.addChild(this.inH)
    this.group.addChild(this.outH)

    this.root.addChild(this.group)
  }

  get showControls () {
    return this.group.visible
  }

  set showControls (value) {
    this.group.visible = value
  }

  setCurve (curve) {
    this.curve = curve
    this.segments = curve.segments
    this.clearSegment()
  }

  // Set the segment we're editing
  setSegment (idx) {
    this.neighbourhood = neighbourhood(this.curve.segments, idx)
    const n = this.neighbourhood

    const inH = n[1].point.add(n[1].handleIn.multiply(this.tangentScale))
    const outH = n[1].point.add(n[1].handleOut.multiply(this.tangentScale))

    this.controlPoint.set(n[1].point)
    this.inP.position.set(inH)
    this.outP.position.set(outH)
    this.inH.segments[0].point.set(inH)
    this.inH.segments[1].point.set(n[1].point)
    this.outH.segments[0].point.set(n[1].point)
    this.outH.segments[1].point.set(outH)
    this.segIdx = idx
  }

  updateControls () {
    if(this.segIdx !== -1) this.setSegment(this.segIdx)
  }

  clearSegment () {
    this.neighbourhood = null
    this.tanIdx = -1
    this.segIdx = -1
  }

  isCleared () {
    return this.segIdx === -1
  }

  // Return false if no tangent point selected
  pick (point) {
    if (this.isCleared()) return false

    if(intersects(point, this.inP.position, PICK_DIST_SQ)) {
      this.tanIdx = 0
    } else if(intersects(point, this.outP.position, PICK_DIST_SQ)) {
      this.tanIdx = 1
    } else {
      this.tanIdx = -1
    }
    return this.tanIdx !== -1
  }

  move (point) {
    if(this.tanIdx === 0) {
      this.inP.position.set(point)
      this.inH.segments[0].point.set(this.inP.position)

      const curr = this.neighbourhood[1]
      curr.handleIn.set(point.subtract(curr.point).multiply(this.invTangentScale))

    } else if(this.tanIdx === 1) {
      this.outP.position.set(point)
      this.outH.segments[1].point.set(this.outP.position)

      const curr = this.neighbourhood[1]
      curr.handleOut.set(point.subtract(curr.point).multiply(this.invTangentScale))
    } else {
      return false
    }
    return true
  }

  release (point) {
    this.tanIdx = -1
  }
}