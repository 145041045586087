import paper from 'paper'
import { lerpPoints } from '../../maths/maths-utils'

const jawClosed = `["Path",{"applyMatrix":true,"segments":[[[429.16617,1018.46946],[2.09152,-0.74034],[-0.9412,0.33316]],[[427.6,1018.9],[0.06207,0.06207],[-0.2,-0.2]],[[421,1005.6],[3.4,7.1],[-10.4,-21.8]],[[383.4,907],[13.8,41.6],[-7.2,-21.7]],[[368.5,851.7],[5.3,24.5],[-3.9,-18]],[[352.3,801.2],[5.7,11.7],[-4.1,-8.3]],[[333.6,782.6],[8.2,3.9],[-9.4,-4.4]],[[293.5,777.8],[26.6,-0.1],[-42,0.2]],[[212.5,769.9],[20.3,6.3],[-21.6,-6.7]],[[173.2,742],[7.9,14.2],[-9.3,-16.6]],[[167.5,694],[-5.4,15.6],[2.6,-7.6]],[[170.4,674],[0.1,10.2],[0,-1.02256]],[[170.35873,670.98992],[0.02766,0.98476],[-0.324,-11.53472]]]}]`

const FULL_OPEN_OFFSET = new paper.Point(5, 42)
const PARTIAL_OPEN_OFFSET = new paper.Point(16, 10) // Labio-dental

// Debug the jaw hierarchy
function printGlobalMatrices(item, depth) {
  if (item.parent) {
    printGlobalMatrices(item.parent, depth + 1);
  }
  console.log(item.globalMatrix.toString(), item.name, depth);
}

export default class jaw {
  constructor (root, closedPath, { colour, width }) {
    this.root = root

    this.animationDuration = .5
    this.raf = 0.

    const closed = new paper.Path()
    closed.importJSON(jawClosed)

    this.closedPath = closed

    this.closedPath.visible = true
    this.closedPath.strokeColor = colour
    this.closedPath.strokeWidth = width

    this.jawGroup_ = new paper.Group()
    this.jawGroup_.applyMatrix = false
    this.jawGroup_.addChild(this.closedPath)

    root.addChild(this.jawGroup_)

    this.startP = null
    this.endP = null

    this.openState = false
    this.labioDentalState = false
  }

  get aniDuration () {
    return this.animationDuration
  }

  set aniDuration (value) {
    this.animationDuration = value
  }

  get jawGroup () { return this.jawGroup_ }

  get offset () {
    return this.jawGroup.position.subtract(this.startP)
  }

  get open () {
    return this.openState
  }

  set open (val) {
    if (this.openState === val) return
    this.openState = val

    const start = performance.now()

    if(!this.startP) {
      this.startMatrix = this.jawGroup_.globalMatrix.clone()
      this.startP = this.jawGroup_.position.clone()
      this.bounds = this.jawGroup_.bounds.clone()
    }
    this.endP = this.jawGroup_.position.add(FULL_OPEN_OFFSET);

    if(this.raf > 0) cancelAnimationFrame(this.raf)

    const startP = this.jawGroup_.position.clone()

    const draw = () => {
      const t = ((performance.now() - start) * 1e-3) / this.aniDuration
      this.jawGroup.position.set(val ? lerpPoints(startP, this.endP, t) : lerpPoints(startP, this.startP, t))

      if(t < 1) this.raf = requestAnimationFrame(draw)
      else {
        this.jawGroup.position.set(val ? this.endP.clone() : this.startP.clone())
        this.raf = 0.
      }
    }

    draw()
  }

  get labioDental () {
    return this.labioDentalState
  }

  set labioDental (val) {
    if (this.labioDentalState === val) return

    this.labioDentalState = val

    const start = performance.now()

    if(!this.startP) {
        this.startMatrix = this.jawGroup_.globalMatrix.clone()
        this.startP = this.jawGroup_.position.clone()
        this.bounds = this.jawGroup_.bounds.clone()
    }
    this.endP = this.jawGroup_.position.add(PARTIAL_OPEN_OFFSET);

    if(this.raf > 0) cancelAnimationFrame(this.raf)

    const startP = this.jawGroup_.position.clone()

    const draw = () => {
    const t = ((performance.now() - start) * 1e-3) / this.animationDuration
    this.jawGroup.position.set(val ? lerpPoints(startP, this.endP, t) : lerpPoints(startP, this.startP, t))

    if(t < 1) this.raf = requestAnimationFrame(draw)
    else {
          this.jawGroup.position.set(val ? this.endP.clone() : this.startP.clone())
          this.raf = 0.
      }
    }

    draw()
  }
}